<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增呼入路由')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="130px" label-position="top"
               ref="validateFrom"
               :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('状态')" prop="status">
              <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="">
              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <span>{{ $t('主叫号码匹配模式') }}
                  <el-icon size="small" color="#409eff">
                    <info-filled/>
                  </el-icon>
                </span>
                <template #content>
                  {{ $t('特殊字符定义如下') }}:<br/>
                  {{ $t('X代表从0到9的任意数字') }}；<br/>
                  {{ $t('Z代表从1到9的任意数字') }}；<br/>
                  {{ $t('N代表从2到9的任意数字') }}；<br/>
                  [12345-9] <br/>
                  {{ $t('代表括内任数字例如在此例中代表号') }}<br/>
                  {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>
                  {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>
                  {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>
                  {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>
                  {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>
                </template>
              </el-tooltip>
              <el-input v-model="ruleForm.caller_pattern" :placeholder="$t('主叫号码匹配模式')" maxlength="32"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('优先级')" prop="priority">
              <el-input-number v-model="ruleForm.priority" :placeholder="$t('优先级')" maxlength="3" :min="0"
                               :max="999"
                               clearable></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('时间条件')">
              <el-select v-model="ruleForm.time_conditions" :placeholder="$t('请选择时间条件可多选')" multiple>
                <el-option v-for="item in times" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addRoutein,
  timeSimple,
} from '@/http/routein.api.js';
import {
  ElNotification
} from 'element-plus';
import {InfoFilled} from "@element-plus/icons-vue";

function timeSimList(state) {
  timeSimple().then(res => {
    state.times = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'incomingCallAddMenu',
  components: {InfoFilled},
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        status: 0,
        caller_pattern: '',
        priority: 0,
        target_type: '',
        target_id: '',
        time_conditions: [],
        did_ext_data: [{
          did_number: "",
          did_ext: "",
          isEdit: true
        }],
      },
      times: [],
      stations: [],
      activeName: 'first',
      tabPosition: 'left',
      value: '',
      checked1: false,
      target_obj: [],
    });
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: t("请输入优先级"),
          trigger: "blur"
        }],
      }
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      timeSimList(state);
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };

    // 新增
    const onSubmit = () => {

      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            priority: state.ruleForm.priority,
            caller_pattern: state.ruleForm.caller_pattern,
            time_conditions: state.ruleForm.time_conditions,
          };
          addRoutein(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm = () => {
      state.ruleForm = {
        name: '',
        status: 0,
        caller_pattern: '',
        priority: 0,
        time_conditions: [],
      };
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.timeConditions {
  display: flex;
  justify-content: space-between;
}

.tip {
  float: left;
  position: relative;
  top: 9px !important;
  left: -3px !important;
}

</style>
