<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑中继')" v-model="isShowDialog" width="60%" :modal-append-to-body="true"
               :append-to-body="true">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('状态')">
                  <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="" prop="type">
                  <el-tooltip class="box-item" effect="dark" placement="bottom">
                    <span>{{ $t('中继类型') }}
                      <el-icon size="small" color="#409eff">
                        <info-filled/>
                      </el-icon>
                    </span>
                    <template #content>
                      {{ $t('注册中继') }}：<br/>
                      <span style="padding-left: 16px">
                        {{ $t('对端提供账号密码等认证信息需要向对端设备进行注册的中继类型通常用于通过互联网对接时己端没有静态公网IP需要向对端设备进行注册或对端设备强制要求注册的场景下使用') }}
                      </span><br/>
                      <br/>
                      {{ $t('点对点中继') }}：<br/>
                      <span style="padding-left: 16px">
                        {{ $t('两端通过IP地址对接无需注册认证通常用于企业内部局域网环境对接本地网关对接或互联网固定IP地址对接的场景') }}
                      </span><br/>
                      <br/>
                      {{ $t('账号中继') }}：<br/>
                      <span style="padding-left: 16px">
                        {{ $t('账号中继一般用于对接PBX与其他设备你可以在IPPBX创建一个账号其他设备通过注册该账号连接到IPPBX') }}
                      </span><br/>
                    </template>
                  </el-tooltip>
                  <el-select v-model="ruleForm.type" :placeholder="$t('请选择中继类型')">
                    <el-option :label="$t('注册中继')" :value="1"></el-option>
                    <el-option :label="$t('点对点中继')" :value="0"></el-option>
                    <el-option :label="$t('账号中继')" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              </el-col>
              <!--              <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '2'">-->
              <!--                <el-form-item :label="$t('中继号码')" prop="callerIdNumber">-->
              <!--                  <el-input v-model="ruleForm.callerIdNumber" placeholder="" maxlength="32" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '2'">-->
              <!--                <el-form-item :label="$t('号码名称')" prop="callerIdName">-->
              <!--                  <el-input v-model="ruleForm.callerIdName" placeholder="" maxlength="32" clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('协议')">
                  <el-input v-model="ruleForm.input" disabled placeholder="SIP" maxlength="16"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('传输协议')" prop="transport">
                  <el-select v-model="ruleForm.transport" :placeholder="$t('请选择传输协议')">
                    <el-option label="UDP" value="UDP"></el-option>
                    <el-option label="TCP" value="TCP"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== 2">
                <el-form-item :label="$t('域名IP地址')" prop="domain">
                  <el-input v-model="ruleForm.domain" placeholder="" clearable maxlength="64"></el-input>
                </el-form-item>
              </el-col>
              <span style="line-height:83px;font-size:15px;" v-if="ruleForm.type !== 2">:</span>
              <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6" v-if="ruleForm.type !== 2">
                <el-form-item :label="$t('端口')" prop="port">
                  <el-input v-model="ruleForm.port" placeholder="5060" maxlength="10"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== 2">
                <el-form-item :label="$t('代理服务器')">
                  <el-input v-model="ruleForm.proxy" placeholder="" clearable maxlength="64"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== 0">
                <el-form-item :label="$t('用户名')" prop="username">
                  <el-input v-model="ruleForm.username" :placeholder="$t('用户名')" maxlength="64" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== 0">
                <el-form-item :label="$t('密码')" prop="auth_password">
                  <el-input v-model="ruleForm.auth_password" :placeholder="$t('密码')" maxlength="64"
                            show-password clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== 0">
                <el-form-item :label="$t('认证名称')" prop="auth_user">
                  <el-input v-model="ruleForm.auth_user" :placeholder="$t('认证名称')" maxlength="64"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('最大并发数')" prop="total_calls">
                  <el-input-number v-model="ruleForm.total_calls" :placeholder="$t('最大并发数')" maxlength="4" :min="0"
                                   :max="9999" clearable></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer" style="float:right">
							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
							<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
						</span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('高级')" name="second">
          <el-form :model="advanced" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
                <h3>{{ $t('VoIP设置') }}</h3>
              </el-col>
              <!--							<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">-->
              <!--								<el-form-item :label="$t('心跳检测')">-->
              <!--									<el-switch v-model="advanced.dtmf" :active-value="1" :inactive-value="0"></el-switch>-->
              <!--								</el-form-item>-->
              <!--							</el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type !== '2'">
                <el-form-item :label="$t('DTMF模式')">
                  <el-select v-model="advanced.dtmf_type">
                    <el-option label="RFC2833" :value="0"></el-option>
                    <el-option label="INBAND" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer" style="float:right">
							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
							<el-button type="primary" @click="onSubSec" size="small">{{ $t('保存') }}</el-button>
						</span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('编码')" name="third">
          <el-form :model="ruleForm" size="small" label-wcidth="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
                <h3>{{ $t('支持编码') }}</h3>
              </el-col>
              <ul class="temp-ul">
                <li>G.711U</li>
                <li>G.711A</li>
                <li>G.722</li>
                <li>G.723</li>
              </ul>
              <!--                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'name',}" :data="leftValue"-->
              <!--                             :titles="[$t('可用编码'), $t('已选编码')]" :button-texts="[$t('移除'), $t('添加')]"-->
              <!--                              @change="getObject">-->
              <!--                </el-transfer>-->
            </el-row>
            <!--            <span class="dialog-footer" style="float:right">-->
            <!--							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>-->
            <!--							<el-button type="primary" size="small">{{ $t('保存') }}</el-button>-->
            <!--						</span>-->
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="DOD" name="fourth">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="left">
            <el-card shadow="never" class="mb10 u-el-button-small">
              <template #header>
                <div style="display: flex;">
                  <el-button type="primary" size="small" @click="onOpenAddDod">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <CirclePlus/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('添加') }}</span>
                  </el-button>
                  <el-button type="primary" size="small" @click="onOpenImpDod">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <Upload/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('导入') }}</span>
                  </el-button>
                  <el-button type="info" size="small" @click="delDodsSub">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <Delete/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('删除') }}</span>
                  </el-button>
                  <el-input size="small" v-model="form.keyword" :placeholder="$t('DOD号码')"
                            style="width:160px;margin-left: auto;" @keydown.enter="dodList">
                    <template #append>
                      <el-button @click="dodList">
                        <el-icon :size="14">
                          <Search/>
                        </el-icon>
                      </el-button>
                    </template>
                  </el-input>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-table :data="form.dodData" stripe style="width: 100%" class="table" id="out-table"
                            @selection-change="selectionChange" @sort-change="changeDodSort">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="number" :label="$t('DOD号码')"
                                     show-overflow-tooltip sortable="custom"></el-table-column>
                    <el-table-column prop="station_dn" :label="$t('绑定分机')"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="create_time" :label="$t('创建时间')"
                                     show-overflow-tooltip sortable="custom" width="180"></el-table-column>
                    <el-table-column prop="path" :label="$t('操作')" width="90" fixed="right">
                      <template #default="scope">
                        <el-button type="text" @click="onOpenEditDod(scope.row)" :title="$t('编辑')">
                          <el-icon :size="16">
                            <Edit/>
                          </el-icon>
                        </el-button>
                        <el-button type="text" @click="delDodSub(scope.row)" :title="$t('删除')"
                                   style="margin-left: 0px !important;">
                          <el-icon :size="16" color="#f63d3d">
                            <Delete/>
                          </el-icon>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                                 @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                                 :page-count="form.pageCount"
                                 :current-page="form.pageNum" background :page-size="form.pageSize"
                                 layout="total, prev, pager, next, jumper"
                                 :total="form.total">
                  </el-pagination>
                  <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                                 :page-size="form.pageSize" small layout=" prev, pager, next"
                                 :page-count="form.pageCount"
                                 :current-page="form.pageNum"
                                 :total="form.total" class="mt15"></el-pagination>
                </el-col>
              </el-row>
            </el-card>
            <!--            <el-card shadow="never" class="mb10">-->
            <!--              <template #header>-->
            <!--                <el-button type="primary" size="small" @click="addDod">{{ $t('添加') }}</el-button>-->
            <!--                <el-button type="primary" size="small">{{ $t('删除') }}</el-button>-->
            <!--                &lt;!&ndash;                <el-button type="primary" size="small" @click="openImportDod">导入</el-button>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <el-button type="primary" size="small">导出</el-button>&ndash;&gt;-->
            <!--              </template>-->
            <!--              <el-row :gutter="35">-->
            <!--                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">-->
            <!--                  <div class="grid-content bg-purple-light">-->
            <!--                    <el-table :data="form.dodData" stripe style="width: 100%;margin-top:15px;" class="table"-->
            <!--                              id="out-table" @cell-mouse-enter="DhandleCellEnter" @cell-mouse-leave="DhandleCellLeave"-->
            <!--                              :row-class-name="DtableRowIndex">-->
            <!--                      <el-table-column type="selection" width="55"></el-table-column>-->
            <!--                      <el-table-column prop="sourceModule" :label="$t('DOD号码')" show-overflow-tooltip>-->
            <!--                        <template #default="scope">-->
            <!--                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.number"-->
            <!--                                    :placeholder="$t('请输入内容')" maxlength="32"-->
            <!--                                    onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>-->
            <!--                          <div v-else class="txt">{{ scope.row.number }}</div>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column prop="food" :label="$t('绑定分机')" show-overflow-tooltip>-->
            <!--                        <template #default="scope">-->
            <!--                          <el-select class="item" v-model="scope.row.station" :placeholder="$t('请选择')">-->
            <!--                            <el-option v-for="item in stations" :key="item.id" :label="item.number"-->
            <!--                                       :value="item.id">-->
            <!--                            </el-option>-->
            <!--                          </el-select>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column prop="path" :label="$t('操作')" width="90">-->
            <!--                        <template #default="scope">-->
            <!--                          <el-button size="mini" type="text" @click="delDod(scope.$index)">{{ $t('删除') }}</el-button>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                    </el-table>-->
            <!--                  </div>-->
            <!--                </el-col>-->
            <!--              </el-row>-->
            <!--            </el-card>-->
          </el-form>
          <addDodP ref="addDodRef" @print="refDodExt"/>
          <editDodP ref="editDodRef" @print="refDodExt"/>
          <impDodP ref="impDodRef" @print="refDodExt"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('改编来电号码')" name="fifth">
          <p>{{ $t('配置相应的模式来重新格式化呼入的号码') }}</p>
          <el-form :model="ruleForm" size="small" label-width="90px">
            <el-card shadow="never" class="mb10">
              <template #header>
                <div class="card-header u-el-button-small">
                  <el-tooltip class="box-item" effect="dark" placement="bottom">
                    <h3>{{ $t('来电号码变换') }}
                      <el-icon size="small" color="#409eff">
                        <info-filled/>
                      </el-icon>
                    </h3>
                    <template #content>
                      {{ $t('特殊字符定义如下') }}:<br/>
                      {{ $t('X代表从0到9的任意数字') }}；<br/>
                      {{ $t('Z代表从1到9的任意数字') }}；<br/>
                      {{ $t('N代表从2到9的任意数字') }}；<br/>
                      [12345-9] <br/>
                      {{ $t('代表括内任数字例如在此例中代表号') }}<br/>
                      {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>
                      {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>
                      {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>
                    </template>
                  </el-tooltip>
                  <el-button type="primary" size="small" @click="addCallerMode">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <CirclePlus/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('添加') }}</span>
                  </el-button>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="grid-content bg-purple-light">
                    <el-table :data="form.callerMode" stripe style="width: 100%;margin-top:15px;" class="table"
                              id="out-table" @cell-mouse-enter="handleCellEnter" @cell-mouse-leave="handleCellLeave"
                              :row-class-name="tableRowIndex">
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column prop="value" :label="$t('匹配模式')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.mode"
                                    :placeholder="$t('请输入内容')" maxlength="32"></el-input>
                          <div v-else class="txt">{{ scope.row.mode }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" :label="$t('删除前置位数')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.remove"
                                    :placeholder="$t('请输入内容')" onkeyup="value=value.replace(/[^\d]/g,'')"
                                    maxlength="3"></el-input>
                          <div v-else class="txt">{{ scope.row.remove }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" :label="$t('前置号码')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.number"
                                    :placeholder="$t('请输入内容')" onkeyup="value=value.replace(/[^\+\d]/g,'')"
                                    maxlength="32"></el-input>
                          <div v-else class="txt">{{ scope.row.number }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="path" :label="$t('操作')" width="90">
                        <template #default="scope">
                          <el-button size="mini" type="text" @click="delCallerMode(scope.$index)" :title="$t('删除')">
                            <el-icon :size="16" color="#f63d3d">
                              <Delete/>
                            </el-icon>
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <!--            <el-card shadow="never">-->
            <!--              <template #header>-->
            <!--                <div class="card-header">-->
            <!--                  <h3 style="display:inline-block">{{ $t('被叫号码变换') }}-->
            <!--                    <el-tooltip class="box-item" effect="dark" placement="bottom">-->
            <!--                      <template #content>-->
            <!--                        以下统一以号码 01086465720为例<br/>-->
            <!--                        号码变换关键字<br/>-->
            <!--                        ADD 表示添加的号码前缀内容 例如 ADD=99 表示 被叫号码被改为 9901086465720 <br/>-->
            <!--                        REMOVE 表示移除的号码位数正数表示移除号码开始位数，负数表示移除结尾位数 例如 REMOVE=1-->
            <!--                        表示示例号<br/>-->
            <!--                        码被改为1086465720 ，REMOVE=-1 表示示例号码被改为 0108646572<br/>-->
            <!--                        REPLACE 表示替换指定的字符 例如 REPLACE=+86,00 表示把+86替换为00，例如-->
            <!--                        +8601086465786，将被替换<br/>-->
            <!--                        为 0001086465786<br/>-->
            <!--                        KEEP 表示保留多少位，例如 KEEP=-8 表示保留后八位 01086465702被替换为 86465720<br/>-->
            <!--                        总结：如号码 +8601086465720 的变换规则为 REPLACE=+86,00;REMOVE=5;ADD=9,则变换后的号码为 <br/>-->
            <!--                        986465720<br/>-->
            <!--                      </template>-->
            <!--                      <img src="/static/img/tip.png" class="tipr"/>-->
            <!--                    </el-tooltip>-->
            <!--                  </h3>-->
            <!--                  <el-button type="primary" icon="el-icon-plus" size="small" @click="cddcalledNumber">{{ $t('添加') }}-->
            <!--                  </el-button>-->
            <!--                </div>-->
            <!--              </template>-->
            <!--              <el-row :gutter="35">-->
            <!--                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">-->
            <!--                  <div class="grid-content bg-purple-light">-->
            <!--                    <el-table :data="form.calledNumber" stripe style="width: 100%;margin-top:15px;" class="table"-->
            <!--                              id="out-table" @cell-mouse-enter="BhandleCellEnter" @cell-mouse-leave="BhandleCellLeave"-->
            <!--                              :row-class-name="calledNumberRowIndex">-->
            <!--                      <el-table-column type="selection" width="55"></el-table-column>-->
            <!--                      <el-table-column prop="value" :label="$t('模式')" show-overflow-tooltip>-->
            <!--                        <template #default="scope">-->
            <!--                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.value"-->
            <!--                                    :placeholder="$t('请输入内容')"></el-input>-->
            <!--                          <div v-else class="txt">{{ scope.row.value }}</div>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column prop="name" :label="$t('替换号码')" show-overflow-tooltip>-->
            <!--                        <template #default="scope">-->
            <!--                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.name"-->
            <!--                                    :placeholder="$t('请输入内容')"></el-input>-->
            <!--                          <div v-else class="txt">{{ scope.row.name }}</div>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column prop="path" :label="$t('操作')" width="90">-->
            <!--                        <template #default="scope">-->
            <!--                          <el-button size="mini" type="text" @click="DelcalledNumber(scope.$index)">{{ $t('删除') }}-->
            <!--                          </el-button>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                    </el-table>-->
            <!--                  </div>-->
            <!--                </el-col>-->
            <!--              </el-row>-->
            <!--            </el-card>-->
            <span class="dialog-footer" style="float:right">
              <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="onSubFiv" size="small">{{ $t('保存') }}</el-button>
            </span>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  findTrunk,
  updateTrunk,
  setCodeTrunk,
  setAdvancedTrunk,
  setTransformTrunk, getDodData, bDelDod, delDod,
} from '@/http/trunk.api.js';

import {
  avaliableStation
} from '@/http/extension.api.js';
import editDodP from "@/views/relay/components/editDod.vue";
import addDodP from "@/views/relay/components/addDod.vue";
import impDodP from "@/views/relay/components/importDod.vue";

function stationBase(state) {
  avaliableStation().then(res => {
    state.stations = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'relayeditMenu',
  components: {impDodP, addDodP, editDodP},
  emits: ["print"],
  setup(props, context) {
    const {t}           = useI18n()
    const state         = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        name: '',
        status: '',
        type: '',
        callerIdNumber: '',
        callerIdName: '',
        total_calls: '',
        transport: '',
        domain: '',
        port: '',
        proxy: '',
        username: '',
        auth_user: '',
        auth_password: '',
      },
      advanced: {
        dtmf: 0,
        dtmf_type: '0',
      },
      stations: [],
      activeName: 'first',
      tabPosition: 'left',
      options: [{
        value: '选项5',
        label: '259'
      }],
      form: {
        callerMode: [],
        calledNumber: [],
        selectionList: [],
        keyword: '',
        dodData: [],
        pageSize: 5,  //每页数据条数
        pageNum: 1,   //页码
        total: 0,     //总数据条数
        pageCount: 0, //总页数
        sortType: '',
        sortBy: ''
      },
      tableData3: [
        {
          'name': 'G.711U'
        },
        {
          'name': 'G.711A'
        },
        {
          'name': 'G.722'
        },
        {
          'name': 'G.723'
        },
      ],
      rightValue: [
        'G.711U',
        'G.711A',
        'G.722',
        'G.723',
      ],
      leftValue: [{
        id: 'G.711U',
        name: 'G.711U',
        disabled: 'disabled',
      },
        {
          id: 'G.711A',
          name: 'G.711A',
          disabled: 'disabled',
        },
        {
          id: 'G.722',
          name: 'G.722',
          disabled: 'disabled',
        },
        {
          id: 'G.723',
          name: 'G.723',
          disabled: 'disabled',
        }
      ],
      editLayoutData: [],
    });
    const dodList       = () => {
      let params = {
        id: state.id,
        keyword: state.form.keyword,
        page: state.form.pageNum,
        sort_type: state.form.sortType,
        sort_by: state.form.sortBy
      }
      getDodData(params).then(res => {
        if (res.code == 200) {
          state.form.dodData   = res.data.rows;
          state.form.pageCount = res.data.pages;
          state.form.pageSize  = res.data.records;
          state.form.total     = res.data.total;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const trunkEditData = (state) => {
      let params = {
        id: state.id
      }
      findTrunk(params).then(res => {
        if (res.code == 200) {
          state.ruleForm           = res.data;
          state.form.callerMode    = res.data.callerChange ? res.data.callerChange : [];
          state.form.calledNumber  = res.data.calleeChange;
          state.advanced.dtmf      = res.data.dtmf;
          state.advanced.dtmf_type = res.data.dtmf_type;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const validateFrom  = ref()
    //校验规则
    const rules         = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
        callerIdNumber: [{
          required: true,
          message: t("请输入中继号码"),
          trigger: "change"
        }],
        transport: [{
          required: true,
          message: t("请选择传输协议"),
          trigger: "change"
        }],
        domain: [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }],
        port: [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }],
        username: [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }],
        auth_password: [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }],
        auth_user: [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }],
        total_calls: [{
          required: true,
          message: t("请选择最大并发数"),
          trigger: "change"
        }],
      }
      return rule;
    });

    const addDodRef     = ref();
    const editDodRef    = ref();
    const impDodRef     = ref();
    const onOpenAddDod  = () => {
      addDodRef.value.openDialog({id: state.id});
    };
    const onOpenEditDod = (row) => {
      editDodRef.value.openDialog({id: state.id, dod_id: row.id});
    };
    const onOpenImpDod  = () => {
      impDodRef.value.openDialog({id: state.id});
    };

    // 打开弹窗
    const openDialog    = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      state.activeName   = 'first';
      dodList();
      stationBase(state);
      trunkEditData(state);
    };
    const openImportDod = () => {
      context.emit('changeTag');
    }
    // 关闭弹窗
    const closeDialog   = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel      = () => {
      closeDialog();
      initForm();
    };
    // 基本设置新增
    const onSubmit      = () => {
      if (state.ruleForm.type == 0) {
        validateFrom.value.rules.auth_password = null;
        validateFrom.value.rules.auth_user     = null;
        validateFrom.value.rules.username      = null;
        validateFrom.value.rules.domain        = [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }];
        validateFrom.value.rules.port          = [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }];
      } else if (state.ruleForm.type == 2) {
        validateFrom.value.rules.domain        = null;
        validateFrom.value.rules.port          = null;
        validateFrom.value.rules.username      = [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_password = [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_user     = [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }];
      } else {
        validateFrom.value.rules.domain        = [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }];
        validateFrom.value.rules.port          = [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_password = [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_user     = [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }];
        validateFrom.value.rules.username      = [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }];
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            type: state.ruleForm.type,
            caller_id_number: state.ruleForm.callerIdNumber,
            caller_id_name: state.ruleForm.callerIdName,
            total_calls: state.ruleForm.total_calls,
            transport: state.ruleForm.transport,
            domain: state.ruleForm.domain,
            port: state.ruleForm.port,
            proxy: state.ruleForm.proxy,
            username: state.ruleForm.username,
            auth_user: state.ruleForm.auth_user,
            auth_password: state.ruleForm.auth_password,
          };
          updateTrunk(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 改编来电号码新增
    const onSubFiv      = () => {
      let params = {
        id: state.id,
        callerChange: state.form.callerMode,
      };
      if (state.form.callerMode.length) {
        for (var i = 0; i < state.form.callerMode.length; i++) {
          if (!state.form.callerMode[i].mode) {
            ElNotification({
              title: t("失败"),
              message: t("匹配模式不能为空"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
          if (!/\d+/.test(state.form.callerMode[i].remove)) {
            ElNotification({
              title: t("失败"),
              message: t("删除前置位数只能设置数字"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
          if (state.form.callerMode[i].number && !/\+?\d+/.test(state.form.callerMode[i].number)) {
            ElNotification({
              title: t("失败"),
              message: t("前置号码格式错误"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
        }
      }
      setTransformTrunk(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog = false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })

    };
    // g高级设置编辑
    const onSubSec      = () => {
      let params = {
        id: state.id,
        dtmf: state.advanced.dtmf,
        dtmf_type: state.advanced.dtmf_type,
      };
      setAdvancedTrunk(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog = false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const getObject     = (value, direction) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = value;
        let params           = {
          id: state.id,
          codes: state.editLayoutData
        }
        setCodeTrunk(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("添加成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = value;
        let params           = {
          id: state.id,
          codes: state.editLayoutData
        }
        setCodeTrunk(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm      = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };
    //主叫号码添加
    const addCallerMode = () => {
      if (state.form.callerMode.length !== 0) {
        if (state.form.callerMode[state.form.callerMode.length - 1].mode && state.form.callerMode[state.form.callerMode.length - 1].remove && state.form.callerMode[state.form.callerMode.length - 1].number) {
          state.form.callerMode.push({
            mode: "",
            remove: "0",
            number: "",
            isEdit: true
          })
        } else {
          ElNotification({
            title: t('失败'),
            message: t('请填写完整内容'),
            type: 'error',
            duration: 2000,
          })
        }
      } else {
        state.form.callerMode.push({
          mode: "",
          remove: "0",
          number: "",
          isEdit: true
        });
      }

    }
    //主叫号码删除
    const delCallerMode = (index) => {
      state.form.callerMode.splice(index, 1);
    }

    //主叫鼠标移入cell
    function handleCellEnter(row) {
      let index                           = row.defineIndex;
      state.form.callerMode[index].isEdit = true
    }

    // 主叫鼠标移出cell
    function handleCellLeave(row) {
      let index                           = row.defineIndex;
      state.form.callerMode[index].isEdit = false
    }

    //获取到每一行的index
    function tableRowIndex({
                             row,
                             rowIndex
                           }) {
      row.defineIndex = rowIndex
    }

    //被叫号码添加
    const cddcalledNumber = () => {
      if (state.form.calledNumber.length !== 0) {
        if (state.form.calledNumber[state.form.calledNumber.length - 1].name && state.form.calledNumber[state.form.calledNumber.length - 1].value) {
          state.form.calledNumber.push({
            name: "",
            value: "",
            isEdit: false
          })
        } else {
          ElNotification({
            title: t('失败'),
            message: t('请填写被叫模式'),
            type: 'error',
            duration: 2000,
          })
        }
      } else {
        state.form.calledNumber.push({
          name: "",
          value: "",
          isEdit: false
        });
      }
    }
    //被叫号码删除
    const DelcalledNumber = (index) => {
      state.form.calledNumber.splice(index, 1);
    }

    //主叫鼠标移入cell
    function BhandleCellEnter(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = true
    }

    // 主叫鼠标移出cell
    function BhandleCellLeave(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = false
    }

    //获取到每一行的index
    function calledNumberRowIndex({
                                    row,
                                    rowIndex
                                  }) {
      row.defineIndex = rowIndex
    }

    const selectionChange = (list) => {
      state.form.selectionList = list.map(item => {
        return item.id
      });
    }
    const changeDodSort   = (column) => {
      state.form.sortBy   = column.prop;
      state.form.sortType = column.order;
      dodList()
    }

    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.form.pageNum = val;
      dodList();
    };

    // 分页改变
    const onHandleSizeChange = (val) => {
      state.form.pageSize = val;
      dodList();
    };

    const delDodSub = (row) => {
      ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            delDod(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                dodList()
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }

    //批量删除
    const delDodsSub = () => {
      if (state.form.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的数据"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.form.selectionList
              }
              bDelDod(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  dodList()
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }

    const refDodExt = () => {
      dodList(state);
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      addCallerMode,
      cddcalledNumber,
      DelcalledNumber,
      delCallerMode,
      handleCellEnter,
      handleCellLeave,
      tableRowIndex,
      BhandleCellEnter,
      BhandleCellLeave,
      calledNumberRowIndex,
      openImportDod,
      ...toRefs(state),
      addDodRef,
      editDodRef,
      impDodRef,
      onOpenAddDod,
      onOpenEditDod,
      onOpenImpDod,
      refDodExt,
      dodList,
      delDodSub,
      delDodsSub,
      selectionChange,
      changeDodSort,
      onHandleCurrentChange,
      onHandleSizeChange,
      getObject,
      onSubSec,
      onSubFiv,
      validateFrom,
      rules
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.card-header h3 {
  font-size: 15px;
}

.temp-ul {
  margin-left: 40px;
  list-style: none;
  font-size: 16px;
  display: inline;
  line-height: 22px;
}
</style>
