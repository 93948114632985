<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('邀请成员')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-select v-model="ruleForm.type" :placeholder="$t('请选择类型')"
                         @change="typeChange">
                <el-option :label="$t('分机')" value="STATION"></el-option>
                <el-option :label="$t('联系人')" value="COLLECT"></el-option>
                <el-option :label="$t('自定义')" value="SELF"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('号码')">
              <el-select v-model="ruleForm.typeId" filterable clearable
                         :placeholder="$t('请选择号码')" v-if="ruleForm.type!=='SELF'">
                <el-option v-for="item in typeData" :key="item.id" :label="item.name" :value="item.number">
                </el-option>
              </el-select>
              <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="32"
                        onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-if="ruleForm.type==='SELF'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('确定') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {
  memberConfManage
} from '@/http/conference.api.js';
import {targetRoutein} from "@/http/routein.api";

export default {
  name: 'memAdd',
  emits: ["print"],
  setup(props, context) {
    const {t}   = useI18n()
    const state = reactive({
      isShowDialog: false,
      id: '',
      typeData: [],
      ruleForm: {
        type: 'STATION',
        typeId: '',
        number: '',
      },
    });

    const validateFrom = ref()

    //校验规则
    const rules = computed(() => {
      const rule = {
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
      };
      return rule;
    })

    const initData = (t) => {
      let param = {
        type: state.ruleForm.type,
      }
      targetRoutein(param).then(res => {
        if (res.code == 200) {
          state.typeData = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    // 打开弹窗
    const openDialog  = (p) => {
      state.isShowDialog = true;
      state.id           = p.id;
      initData(state.ruleForm.type);
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            id: state.id,
            handle: 'add',
            type: 'other',
            members: state.ruleForm.type === 'SELF' ? [state.ruleForm.number] : [state.ruleForm.typeId],
          };
          memberConfManage(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              context.emit("refMem", {
                id: 'other',
                memType: 'out',
                number: state.ruleForm.type === 'SELF' ? state.ruleForm.number : state.ruleForm.typeId
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm = {
        type: 'STATION',
        typeId: '',
        number: '',
      };
    };

    const typeChange = (o) => {
      if (o !== 'SELF') {
        initData(o);
      }
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
      typeChange,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}
</style>
