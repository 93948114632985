<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增中继')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
               ref="validateFrom"
               :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('状态')">
              <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="" prop="type">
              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <span>{{ $t('中继类型') }}
                  <el-icon size="small" color="#409eff">
                    <info-filled/>
                  </el-icon>
                </span>
                <template #content>
                  {{ $t('注册中继') }}：<br/>
                  <span style="padding-left: 16px">
                    {{ $t('对端提供账号密码等认证信息需要向对端设备进行注册的中继类型通常用于通过互联网对接时己端没有静态公网IP需要向对端设备进行注册或对端设备强制要求注册的场景下使用') }}
                  </span><br/>
                  <br/>
                  {{ $t('点对点中继') }}：<br/>
                  <span style="padding-left: 16px">
                    {{ $t('两端通过IP地址对接无需注册认证通常用于企业内部局域网环境对接本地网关对接或互联网固定IP地址对接的场景') }}
                  </span><br/>
                  <br/>
                  {{ $t('账号中继') }}：<br/>
                  <span style="padding-left: 16px">
                    {{ $t('账号中继一般用于对接PBX与其他设备你可以在IPPBX创建一个账号其他设备通过注册该账号连接到IPPBX') }}
                  </span><br/>
                </template>
              </el-tooltip>
              <el-select v-model="ruleForm.type" :placeholder="$t('请选择中继类型')">
                <el-option :label="$t('注册中继')" value="1"></el-option>
                <el-option :label="$t('点对点中继')" value="0"></el-option>
                <el-option :label="$t('账号中继')" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          </el-col>
          <!--          <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12">-->
          <!--            <el-form-item :label="$t('中继号码')" prop="caller_id_number">-->
          <!--              <el-input v-model="ruleForm.caller_id_number" placeholder="" maxlength="32" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12">-->
          <!--            <el-form-item :label="$t('号码名称')" prop="caller_id_name">-->
          <!--              <el-input v-model="ruleForm.caller_id_name" placeholder="" maxlength="32" clearable></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('协议')">
              <el-input v-model="ruleForm.input" disabled placeholder="SIP" maxlength="16"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('传输协议')" prop="transport">
              <el-select v-model="ruleForm.transport" :placeholder="$t('请选择传输协议')">
                <el-option label="UDP" value="UDP"></el-option>
                <el-option label="TCP" value="TCP"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="15" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '2'">
            <el-form-item :label="$t('域名IP地址')" prop="domain">
              <el-input v-model="ruleForm.domain" placeholder="" clearable maxlength="64"></el-input>
            </el-form-item>
          </el-col>
          <span style="line-height:83px;font-size:15px;" v-show="ruleForm.type !== '2'">:</span>
          <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6" v-show="ruleForm.type !== '2'">
            <el-form-item :label="$t('端口')" prop="port">
              <el-input v-model="ruleForm.port" placeholder="5060" maxlength="10"
                        onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '2'">
            <el-form-item :label="$t('代理服务器')">
              <el-input v-model="ruleForm.proxy" placeholder="" clearable maxlength="64"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '0'">
            <el-form-item :label="$t('用户名')" prop="username">
              <el-input v-model="ruleForm.username" :placeholder="$t('用户名')" maxlength="64" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '0'">
            <el-form-item :label="$t('密码')" prop="auth_password">
              <el-input v-model="ruleForm.auth_password" :placeholder="$t('密码')" maxlength="64" show-password
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type !== '0'">
            <el-form-item :label="$t('认证名称')" prop="auth_user">
              <el-input v-model="ruleForm.auth_user" :placeholder="$t('认证名称')" maxlength="64" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('最大并发数')" prop="total_calls">
              <el-input-number v-model="ruleForm.total_calls" :placeholder="$t('最大并发数')" maxlength="4" :min="0"
                               :max="9999" clearable></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- </el-tab-pane> -->
      <!-- <el-tab-pane label="编码" name="second">
        <el-form :model="ruleForm" size="small" label-width="90px">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-transfer v-model="value" :props="{ key: 'value', label: 'desc',}" :data="data" filterable
                :titles="['可选择', '已选择']" :button-texts="['移除', '添加']" filter-placeholder="搜索编码">
              </el-transfer>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="高级" name="third">
        <el-form :model="ruleForm" size="small" label-width="90px">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
              <h3>VoIP设置</h3>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="心跳检测">
                <el-switch v-model="ruleForm.status"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="musicUrl1 !== '2'">
              <el-form-item label="DTMF模式">
                <el-select v-model="ruleForm.department">
                  <el-option label="RFC2833" value="typer0"></el-option>
                  <el-option label="Inband" value="typer1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :model="form" size="small" label-width="90px">
          <h3 style="margin-bottom:20px;">DID设置</h3>
          <el-row :gutter="35" v-for="(item, index) in form.dynamicItem" :key="index">
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
              <el-form-item label="DID号码">
                <el-input v-model="item.did" placeholder="名称" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
              <el-form-item label="DNIS名称">
                <el-input v-model="ruleForm.dnis" placeholder="名称" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-button type="danger" icon="el-icon-delete" circle size="mini" style="margin-right:5px;"
                v-if="index !== 0" @click="deleteItem(item, index)"></el-button>
              <el-button type="primary" icon="el-icon-plus" circle size="mini"
                v-if="index+1 == form.dynamicItem.length" @click="addItem"></el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="DOD" name="fourth">
        <el-form :model="ruleForm" size="small" label-width="90px">
          <el-card shadow="never">
            <template #header>
              <el-button type="primary" size="small" @click="dodcalledNumber">添加</el-button>
              <el-button type="primary" size="small">删除</el-button>
              <el-button type="primary" size="small">导入</el-button>
              <el-button type="primary" size="small">导出</el-button>
            </template>
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple-light">
                  <el-table :data="form.DODdata" stripe style="width: 100%;margin-top:15px;" class="table"
                    id="out-table" @cell-mouse-enter="DhandleCellEnter" @cell-mouse-leave="DhandleCellLeave"
                    :row-class-name="DtableRowIndex">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="sourceModule" label="DOD号码" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.sourceModule"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.sourceModule}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="replaceMode" label="DOD名称" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.replaceMode"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.replaceMode}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="food" label="绑定分机" show-overflow-tooltip>
                      <template #default="scope">
                        <el-select class="item" v-model="scope.row.food" placeholder="请选择">
                          <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="path" label="操作" width="90">
                      <template #default="scope">
                        <el-button size="mini" type="text" @click="DelDODdata(scope.$index)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-form>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="改编来电号码" name="fifth">
        <p>配置相应的模式来重新格式化呼入和呼出的号码。</p>
        <el-form :model="ruleForm" size="small" label-width="90px">
          <el-card shadow="never">
            <template #header>
              <div class="card-header">
                <h3 style="display:inline-block">主叫号码变换</h3>
                <el-button type="primary" icon="el-icon-plus" size="small" @click="addcallingNumber">添加</el-button>
              </div>
            </template>
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple-light">
                  <el-table :data="form.callingNumber" stripe style="width: 100%;margin-top:15px;" class="table"
                    id="out-table" @cell-mouse-enter="handleCellEnter" @cell-mouse-leave="handleCellLeave"
                    :row-class-name="tableRowIndex">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="sourceModule" label="源模式" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.sourceModule"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.sourceModule}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="replaceMode" label="替换模式" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.replaceMode"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.replaceMode}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="path" label="操作" width="90">
                      <template #default="scope">
                        <el-button size="mini" type="text" @click="DelcallingNumber(scope.$index)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <el-card shadow="never">
            <template #header>
              <div class="card-header">
                <h3 style="display:inline-block">被叫号码变换</h3>
                <el-button type="primary" icon="el-icon-plus" size="small" @click="cddcalledNumber">添加</el-button>
              </div>
            </template>
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple-light">
                  <el-table :data="form.calledNumber" stripe style="width: 100%;margin-top:15px;" class="table"
                    id="out-table" @cell-mouse-enter="BhandleCellEnter" @cell-mouse-leave="BhandleCellLeave"
                    :row-class-name="calledNumberRowIndex">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="sourceModule" label="源模式" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.sourceModule"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.sourceModule}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="replaceMode" label="替换模式" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.replaceMode"
                          placeholder="请输入内容"></el-input>
                        <div v-else class="txt">{{scope.row.replaceMode}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="path" label="操作" width="90">
                      <template #default="scope">
                        <el-button size="mini" type="text" @click="DelcalledNumber(scope.$index)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-form>
      </el-tab-pane>
    </el-tabs> -->
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'relayAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}             = useI18n()
    const state           = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        status: 0,
        type: '',
        caller_id_number: '',
        caller_id_name: '',
        total_calls: '',
        transport: 'UDP',
        domain: '',
        port: '',
        proxy: '',
        username: '',
        auth_user: '',
        auth_password: '',
      },
      activeName: 'first',
      tabPosition: 'left',
      musicUrl1: '',
      options: [{
        value: '选项1',
        label: '255'
      }, {
        value: '选项2',
        label: '256'
      }, {
        value: '选项3',
        label: '257'
      }, {
        value: '选项4',
        label: '258'
      }, {
        value: '选项5',
        label: '259'
      }],
      form: {
        dynamicItem: [{
          did: "",
          dnis: ""
        }],
        callingNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
        calledNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
        DODdata: [{
          sourceModule: "1",
          replaceMode: "1",
          food: '选项5',
          isEdit: false
        }],
      },
    });
    const validateFrom    = ref()
    //校验规则
    const rules           = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
        caller_id_number: [{
          required: true,
          message: t("请输入中继号码"),
          trigger: "change"
        }],
        transport: [{
          required: true,
          message: t("请选择传输协议"),
          trigger: "change"
        }],
        domain: [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }],
        port: [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }],
        username: [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }],
        auth_password: [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }],
        auth_user: [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }],
        total_calls: [{
          required: true,
          message: t("请选择最大并发数"),
          trigger: "change"
        }],
      }
      return rule;
    });
    // 打开弹窗
    const openDialog      = () => {
      state.isShowDialog = true;
      state.activeName   = 'first';
    };
    // 关闭弹窗
    const closeDialog     = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel        = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit        = () => {
      if (state.ruleForm.type == 0) {
        validateFrom.value.rules.auth_password = null;
        validateFrom.value.rules.auth_user     = null;
        validateFrom.value.rules.username      = null;
        validateFrom.value.rules.domain        = [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }];
        validateFrom.value.rules.port          = [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }];
      } else if (state.ruleForm.type == 2) {
        validateFrom.value.rules.domain        = null;
        validateFrom.value.rules.port          = null;
        validateFrom.value.rules.username      = [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_password = [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_user     = [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }];
      } else {
        validateFrom.value.rules.domain        = [{
          required: true,
          message: t("请输入域名ip"),
          trigger: "blur"
        }];
        validateFrom.value.rules.port          = [{
          required: true,
          message: t("请输入端口号"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_password = [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }];
        validateFrom.value.rules.auth_user     = [{
          required: true,
          message: t("请输入认证名称"),
          trigger: "blur"
        }];
        validateFrom.value.rules.username      = [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }];
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            type: state.ruleForm.type,
            caller_id_number: state.ruleForm.caller_id_number,
            caller_id_name: state.ruleForm.caller_id_name,
            transport: state.ruleForm.transport,
            domain: state.ruleForm.domain,
            port: state.ruleForm.port,
            total_calls: state.ruleForm.total_calls,
            proxy: state.ruleForm.proxy,
            username: state.ruleForm.username,
            auth_user: state.ruleForm.auth_user,
            auth_password: state.ruleForm.auth_password,
          };
          addTrunk(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm        = () => {
      state.ruleForm.name             = '';
      state.ruleForm.status           = 0;
      state.ruleForm.type             = '';
      state.ruleForm.caller_id_number = '';
      state.ruleForm.caller_id_name   = '';
      state.ruleForm.total_calls      = '';
      state.ruleForm.transport        = 'UDP';
      state.ruleForm.domain           = '';
      state.ruleForm.port             = '';
      state.ruleForm.proxy            = '';
      state.ruleForm.username         = '';
      state.ruleForm.auth_user        = '';
      state.ruleForm.auth_password    = '';
    };
    //DID表单添加
    const addItem         = () => {
      state.form.dynamicItem.push({
        did: "",
        dnis: "",
        isEdit: false
      });
    }
    //DID表单删除
    const deleteItem      = (index) => {
      state.form.dynamicItem.splice(index, 1);
    }
    //DOD添加
    const dodcalledNumber = () => {
      state.form.DODdata.push({
        sourceModule: "1",
        replaceMode: "1",
        food: '选项5',
        isEdit: false
      });
    }
    //DOD删除
    const DelDODdata      = (index) => {
      state.form.DODdata.splice(index, 1);
    }

    //DOD鼠标移入cell
    function DhandleCellEnter(row) {
      let index                        = row.defineIndex;
      state.form.DODdata[index].isEdit = true
    }

    // DOD鼠标移出cell
    function DhandleCellLeave(row) {
      let index                        = row.defineIndex;
      state.form.DODdata[index].isEdit = false
    }

    //获取到每一行的index
    function DtableRowIndex({
                              row,
                              rowIndex
                            }) {
      row.defineIndex = rowIndex
    }

    //主叫号码添加
    const addcallingNumber = () => {
      state.form.callingNumber.push({
        sourceModule: "1",
        replaceMode: "1",
        isEdit: false
      });
    }
    //主叫号码删除
    const DelcallingNumber = (index) => {
      state.form.callingNumber.splice(index, 1);
    }

    //主叫鼠标移入cell
    function handleCellEnter(row) {
      let index                              = row.defineIndex;
      state.form.callingNumber[index].isEdit = true
    }

    // 主叫鼠标移出cell
    function handleCellLeave(row) {
      let index                              = row.defineIndex;
      state.form.callingNumber[index].isEdit = false
    }

    //获取到每一行的index
    function tableRowIndex({
                             row,
                             rowIndex
                           }) {
      row.defineIndex = rowIndex
    }

    //被叫号码添加
    const cddcalledNumber = () => {
      state.form.calledNumber.push({
        sourceModule: "1",
        replaceMode: "1"
      });
    }
    //被叫号码删除
    const DelcalledNumber = (index) => {
      state.form.calledNumber.splice(index, 1);
    }

    //主叫鼠标移入cell
    function BhandleCellEnter(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = true
    }

    // 主叫鼠标移出cell
    function BhandleCellLeave(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = false
    }

    //获取到每一行的index
    function calledNumberRowIndex({
                                    row,
                                    rowIndex
                                  }) {
      row.defineIndex = rowIndex
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      addItem,
      deleteItem,
      addcallingNumber,
      cddcalledNumber,
      DelcalledNumber,
      DelcallingNumber,
      handleCellEnter,
      handleCellLeave,
      tableRowIndex,
      BhandleCellEnter,
      BhandleCellLeave,
      calledNumberRowIndex,
      dodcalledNumber,
      DelDODdata,
      DhandleCellEnter,
      DhandleCellLeave,
      DtableRowIndex,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}


.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

</style>
