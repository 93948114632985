<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('时间条件')">
          <div>
            <div class="system-user-search mb15">
              <div>
                <div>
                  <el-button size="small" type="primary" @click="onOpenAddMenu">
                    {{ $t('添加') }}
                  </el-button>
                  <el-button size="small" type="info" @click="deleteRouteouts">
                    {{ $t('删除') }}
                  </el-button>
                </div>
              </div>
            </div>
            <el-table :data="tableData" stripe style="width: 100%" class="table" id="out-table"
                      @selection-change="selectionChange" @sort-change="changeTableSort">
              <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
              <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="start_time" :label="$t('开始时间')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="end_time" :label="$t('结束时间')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="workday" :label="$t('假期类型')" width="120" show-overflow-tooltip
                               sortable="custom">
                <template #default="scope">
                  <el-tag size="medium" v-if="scope.row.workday == 0">{{ $t('所有日期') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.workday == 1">{{ $t('工作日') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.workday == 2">{{ $t('休息日') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.workday == 3">{{ $t('节假日') }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                               sortable="custom" width="180"></el-table-column>
              <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
                <template #default="scope">
                  <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)" :disabled="scope.row.type">
                    {{ $t('编辑') }}
                  </el-button>
                  <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                             class="u-delete" style="margin-left: 0px !important;" :disabled="scope.row.type">
                    {{ $t('删除') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                           @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                           :page-count="time.pageCount"
                           :current-page="time.pageNum" background :page-size="time.pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="time.total">
            </el-pagination>
            <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                           :page-size="time.pageSize" small layout=" prev, pager, next" :page-count="time.pageCount"
                           :current-page="time.pageNum" :total="time.total" class="mt15"></el-pagination>
          </div>
          <AddMenu ref="addMenuRef" @print="print"/>
          <EditMenu ref="editMenuRef" @print="print"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('假期')">
          <div>
            <div class="system-user-search mb15">
              <div>
                <div>
                  <el-button size="small" type="primary" @click="vaonOpenAddMenu">
                    {{ $t('添加') }}
                  </el-button>
                  <el-button size="small" type="info" @click="vadeleteDay">{{ $t('删除') }}</el-button>
                </div>
              </div>
            </div>
            <el-table :data="dayData" stripe style="width: 100%" class="table" id="out-table"
                      @selection-change="selectionChanges" @sort-change="changeTableSorts">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="code" :label="$t('类型')" show-overflow-tooltip>
                <template #default="scope">
                  <el-tag size="medium" v-if="scope.row.code == 1">{{ $t('休息日') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.code == 2">{{ $t('工作日') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.code == 3">{{ $t('节假日') }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="start_time" :label="$t('开始时间')" show-overflow-tooltip
                               sortable="custom"></el-table-column>
              <el-table-column prop="end_time" :label="$t('结束时间')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="remark" :label="$t('说明')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
                <template #default="scope">
                  <el-button size="mini" type="text" @click="vaonOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
                  <el-button size="mini" type="text" @click="vaonRowDel(scope.row)"
                             class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                           @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                           :page-count="day.pageCount"
                           :current-page="day.pageNum" background :page-size="day.pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="day.total">
            </el-pagination>
            <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                           :page-size="day.pageSize" small layout=" prev, pager, next" :page-count="day.pageCount"
                           :current-page="day.pageNum" :total="day.total" class="mt15"></el-pagination>
          </div>
          <vaAddMenu ref="vaaddMenuRef" @printr="printr"/>
          <vaEditMenu ref="vaeditMenuRef" @printr="printr"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import {
  toRefs,
  reactive,
  ref,
  onMounted,
} from 'vue';
import {useI18n} from "vue-i18n";
import AddMenu from './ContimeConditions/addMenu';
import EditMenu from './ContimeConditions/editMenu';
import vaAddMenu from './ContimeConditions/vaaddMenu';
import vaEditMenu from './ContimeConditions/vaeditMenu';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  getTime,
  getDay,
  deleteTime,
  deleteDay,
  batchdelTime,
  batchdelDay
} from '@/http/setUp.api.js';

export default {
  name: 'timeConditions',
  components: {
    AddMenu,
    EditMenu,
    vaAddMenu,
    vaEditMenu
  },
  setup() {
    const {t}        = useI18n()
    const state      = reactive({
      sysTitle: '',
      tableData: [],
      dayData: [],
      time: {
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
      },
      day: {
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
      },
      clientWidth: '',
      selectionList: '',
      selectionLists: '',
      sortType: '',
      sortBy: '',
      sortTypes: '',
      sortBys: ''
    });
    const blackList  = (state) => {
      let params = {
        page: state.time.pageNum,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      getTime(params).then(res => {
        if (res.code == 200) {
          state.tableData      = res.data.list;
          state.time.pageCount = res.data.pages;
          state.time.pageSize  = res.data.length;
          state.time.total     = res.data.rows;
          state.sysTitle       = res.tag;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const blackListr = (state) => {
      let paramr = {
        page: state.day.pageNum,
        sort_type: state.sortTypes,
        sort_by: state.sortBys
      }
      getDay(paramr).then(res => {
        if (res.code == 200) {
          state.dayData       = res.data.list;
          state.day.pageCount = res.data.pages;
          state.day.pageSize  = res.data.length;
          state.day.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const print      = () => {
      blackList(state);
    }
    const printr     = () => {
      blackListr(state)
    }
    onMounted(() => {
      blackList(state);
      blackListr(state);
      state.clientWidth = window.innerWidth;
    })
    const addMenuRef            = ref();
    // 打开时间条件新增菜单弹窗
    const onOpenAddMenu         = () => {
      addMenuRef.value.openDialog();
    };
    const editMenuRef           = ref();
    // 打开时间条件编辑菜单弹窗
    const onOpenEditMenu        = (row) => {
      editMenuRef.value.openDialog(row);
    };
    const vaaddMenuRef          = ref();
    // 打开假期新增菜单弹窗
    const vaonOpenAddMenu       = () => {
      vaaddMenuRef.value.openDialog();
    };
    const vaeditMenuRef         = ref();
    // 打开假期编辑菜单弹窗
    const vaonOpenEditMenu      = (row) => {
      vaeditMenuRef.value.openDialog(row);
    };
    // 时间条件当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该时间吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteTime(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    // 假期当前行删除
    const vaonRowDel            = (row) => {
      ElMessageBox.confirm(t("你确定要删除该假期吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteDay(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackListr(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    const selectionChange       = (list) => {
      let array           = list.map(item => {
        return item.id
      })
      state.selectionList = array;
    }
    //时间条件批量删除
    const deleteRouteouts       = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的时间"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该时间吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              batchdelTime(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    const selectionChanges      = (list) => {
      let array            = list.map(item => {
        return item.id
      })
      state.selectionLists = array;
    }
    //假期批量删除
    const vadeleteDay           = () => {
      if (state.selectionLists.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的假期"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该假期吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionLists
              }
              batchdelDay(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackListr(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      blackList(state)
    }
    const changeTableSorts      = (column) => {
      state.sortBys   = column.prop;
      state.sortTypes = column.order;
      blackListr(state)
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
    };

    const selectable = (row) => {
      if (row.type) {
        return false;
      } else {
        return true;
      }
    }
    return {
      ...toRefs(state),
      addMenuRef,
      editMenuRef,
      changeTableSort,
      changeTableSorts,
      onOpenAddMenu,
      onOpenEditMenu,
      onRowDel,
      onHandleSizeChange,
      onHandleCurrentChange,
      vaonOpenAddMenu,
      vaonOpenEditMenu,
      vaaddMenuRef,
      vaeditMenuRef,
      selectionChange,
      selectionChanges,
      deleteRouteouts,
      print,
      printr,
      vaonRowDel,
      vadeleteDay,
      selectable,
    }
  }
}
</script>
<style lang="scss" scoped>
.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
