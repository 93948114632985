<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title">{{ $t('呼出路由') }}</h3>
      <div class="system-user-search">
        <div class="pb10">
          <div>
            <el-button size="small" type="primary" @click="onOpenAddMenu">{{ $t('添加') }}</el-button>
            <el-button size="small" type="primary" @click="onOpenImport">{{ $t('导入') }}</el-button>
            <el-button size="small" type="info" @click="deleteRouteouts">{{ $t('删除') }}</el-button>
          </div>
        </div>
        <div>
          <el-input size="small" v-model="input" :placeholder="$t('路由名称')" prefix-icon="el-icon-search"
                    style="max-width: 180px;margin-right:3px;" @keydown.enter="handleSearch"></el-input>
          <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe style="width: 100%" class="table"
                id="out-table" @selection-change="selectionChange" @sort-change="changeTableSort">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('状态')">
          <template #default="scope">
            <el-tag size="medium" type="success" v-if="scope.row.status == 1">{{ $t('启用') }}</el-tag>
            <el-tag size="medium" type="info" v-if="scope.row.status == 0">{{ $t('禁用') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="call_authority" :label="$t('权限')" show-overflow-tooltip>
          <template #default="scope">
            <el-tag size="medium" v-if="scope.row.call_authority == 0">{{ $t('禁止') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.call_authority == 1">{{ $t('局内') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.call_authority == 2">{{ $t('本地') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.call_authority == 4">{{ $t('国内') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.call_authority == 8">{{ $t('国际权限') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="priority" :label="$t('优先级')" show-overflow-tooltip
                         sortable="custom" width="100"></el-table-column>
        <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                         sortable="custom" width="180"></el-table-column>
        <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
          <template #default="scope">
            <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
            <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                       class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                     @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                     :current-page="pageNum" background :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                     :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                     :current-page="pageNum"
                     :total="total" class="mt15"></el-pagination>
    </el-card>
    <AddMenu ref="addMenuRef" @print="print"/>
    <EditMenu ref="editMenuRef" @print="print"/>
    <ImportMenu ref="importMenuRef" @print="print"/>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import AddMenu from './components/addMenu.vue';
import EditMenu from './components/editMenu.vue';
import ImportMenu from './components/importMenu.vue';
import {
  getRouteout,
  deleteRouteout,
  batchdelRouteout
} from '@/http/routeout.api.js'
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import router from "@/router";

export default {
  name: 'incomingCall',
  components: {
    AddMenu,
    EditMenu,
    ImportMenu
  },
  setup() {
    const {t}       = useI18n()
    let state       = reactive({
      sysTitle: '',
      tableData: [],
      input: ref(''),
      pageSize: 5, //每页数据条数
      pageNum: 1, //页码
      total: 0, //总数据条数
      pageCount: 0, //总页数
      selectionList: [],
      clientWidth: '',
      sortBy: '',
      sortType: ''
    });
    const blackList = (state) => {
      let params = {
        page: state.pageNum //页码
      }
      getRouteout(params).then(res => {
        if (res.code == 200) {
          state.tableData = res.data.rows;
          state.pageCount = res.data.total;
          state.pageSize  = res.data.length;
          state.total     = res.data.records;
          state.sysTitle  = res.tag;
          if (res.tag) {
            router.push('/setUp/systemSetup')
          }
        } else {
          ElNotification({
            title: t("失败"),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const print     = () => {
      blackList(state);
    }
    onMounted(() => {
      blackList(state);
      state.clientWidth = window.innerWidth;
    })
    const addMenuRef            = ref();
    const editMenuRef           = ref();
    const importMenuRef         = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu         = () => {
      addMenuRef.value.openDialog();
    };
    // 打开编辑菜单弹窗
    const onOpenEditMenu        = (row) => {
      editMenuRef.value.openDialog(row);
    };
    const onOpenImport          = () => {
      importMenuRef.value.openDialog();
    };
    // 当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该路由吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteRouteout(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    //搜索查询呼出路由
    const handleSearch          = () => {
      let params = {
        length: state.pageSize,
        page: state.pageNum,
        keyword: state.input,
        sort_by: state.sortBy,
        sort_type: state.sortType
      }
      getRouteout(params).then(res => {
        if (res.code == 200) {
          state.tableData = res.data.rows;
          state.pageCount = res.data.page;
          state.total     = res.data.records;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const selectionChange       = (list) => {
      let array           = list.map(item => {
        return item.id
      })
      state.selectionList = array;
    }
    //批量删除
    const deleteRouteouts       = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的路由"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该路由吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              batchdelRouteout(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      handleSearch()
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      if (state.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      if (state.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    return {
      ...toRefs(state),
      onRowDel,
      changeTableSort,
      onOpenAddMenu,
      onOpenEditMenu,
      onOpenImport,
      addMenuRef,
      editMenuRef,
      importMenuRef,
      onHandleSizeChange,
      onHandleCurrentChange,
      print,
      handleSearch,
      deleteRouteouts,
      selectionChange
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}
</style>
