import request from './index.js'

//获取总机列表
export function getIvr(data={}) {
  return request({
    url: "/api/ivr/search",
    method: "GET",
    params: data
  })
}

//总机添加
export function addIvr(data={}) {
  return request({
    url: "/api/ivr/add",
    method: "POST",
    data
  })
}

//总机删除
export function deleteIvr(data={}) {
  return request({
    url: "/api/ivr/delete",
    method: "DELETE",
    data
  })
}

//获取单个总机列表
export function findIvr(data={}) {
  return request({
    url: "/api/ivr/find",
    method: "GET",
    params: data
  })
}

//总机编辑
export function updateIvr(data={}) {
  return request({
    url: "/api/ivr/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}