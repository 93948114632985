<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('导入呼出路由')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top" ref="validateFrom">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('上传文件')">
              <el-upload class="upload-demo"
                         action
                         :http-request="uploadFile"
                         :limit="1"
                         :on-exceed="handleExceed"
                         :file-list="fileList">
                <el-button size="small" type="primary">{{ $t('上传文件') }}</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('下载模版')">
              <el-button type="primary" @click="handleExport()">{{ $t('下载文件模板') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
} from 'vue';
import {hideLoading, showLoading} from "@/http/loading";
import {
  impRouteout,
} from '@/http/routeout.api.js';
import {ElNotification} from "element-plus";
import {useI18n} from "vue-i18n";

export default {
  name: 'importMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      fileList: [],
      ruleForm: {
        name: '',
        filename: '',
      },
    });
    const validateFrom = ref()

    const handleExport = () => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href          = '/static/template/routeimport.xlsx';
      a.download      = '呼出路由导入模板' + '.xlsx';
      a.style.display = 'none';
      a.click();
      document.body.removeChild(a);
    }

    //上传文件
    const uploadFile = (fileObj) => {

      showLoading();
      let formData = new FormData();
      formData.append("file", fileObj.file)
      impRouteout(formData).then(res => {
        hideLoading();
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("上传成功"),
            type: 'success',
            duration: 2000,
          });
          validateFrom.value.resetFields();
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
          state.fileList = [];
        }
      })
    }

    // 打开弹窗
    const openDialog  = () => {
      state.isShowDialog = true;
      state.activeName   = 'first';
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };

    // 取消
    const onCancel = () => {
      initForm();
      closeDialog();
    };

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm = () => {
      state.ruleForm.name     = '';
      state.ruleForm.filename = '';
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      ...toRefs(state),
      validateFrom,
      handleExport,
      uploadFile,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tip {
  float: left;
  position: relative;
  top: 9px !important;
  left: -3px !important;
}
</style>
