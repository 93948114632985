<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title">{{ $t('全部成员') }}</h3>
      <div class="system-user-search">
        <div style="padding-bottom:10px;display: flex;align-items: center;">
          <div style="display: flex;">
            <el-button size="small" type="primary" @click="onAddConf(1)" :disabled="selectVisible">
              {{ $t('预定义会议') }}
            </el-button>
            <el-button size="small" type="primary" @click="onAddConf(2)" :disabled="selectVisible">
              {{ $t('立即会议') }}
            </el-button>
          </div>
          <div style="display: flex;" v-show="selectVisible">
            <div style="margin-left:15px;">
              <el-tag size="mini" type="primary">{{ $t('已选择') }}：{{ selectNumbers.length }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;padding-top:10px;">
        <DepartmentMenu @eventIsNum="receiveChildNum" ref="RefCid"/>
        <Member @eventIsNum="receiveSelectMem" ref="confMemRef"/>
      </div>
      <div class="u-conf-btn" v-show="selectVisible">
        <span class="u-conf-btn-title">{{ addTitle }}...</span>
        <el-button size="mini" type="primary" @click="addConf">
          {{ $t('确定') }}
        </el-button>
        <el-button size="mini" type="secondary" @click="onCancelConf">
          {{ $t('取消') }}
        </el-button>
      </div>
      <ManageMenu ref="manageMenuRef"/>
    </el-card>
    <el-dialog v-model="addVisible" width="40%"
               :title="addTitle" :before-close="handleCloseVisible">
      <el-form v-if="addVisible" size="small" label-width="80px" label-position="left"
               ref="validateFrom" :model="ruleParam" :rules="rules">
        <el-form-item :label="$t('名称')" prop="name">
          <el-input v-model="ruleParam.name" :placeholder="$t('会议名称')" maxlength="16"></el-input>
        </el-form-item>
        <el-form-item :label="$t('号码')" prop="number">
          <el-input v-model="ruleParam.number" :placeholder="$t('会议号码')" maxlength="8"></el-input>
        </el-form-item>
        <el-form-item :label="$t('主持人')" prop="promoter">
          <el-select v-model="ruleParam.promoter" filterable clearable :placeholder="$t('主持人')">
            <el-option v-for="(item,index) in selectNumbers" :key="index" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('备注')">
          <el-input v-model="ruleParam.remark" :placeholder="$t('备注信息')" maxlength="64" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
          <el-button @click="onCancelAdd" size="small">{{ $t('取消') }}</el-button>
          <el-button type="primary" @click="onSubAdd" size="small">{{ $t('确定') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted, computed
} from 'vue';
import {
  addConfManage, nowConfManage,
} from '@/http/conference.api.js'
import {
  ElNotification
} from 'element-plus';
import {useI18n} from "vue-i18n";
import DepartmentMenu from "./components/confDep.vue";
import Member from "./components/confMem.vue";
import ManageMenu from "@/views/meet/components/confManage.vue";

export default {
  name: 'confMember',
  components: {
    ManageMenu,
    Member,
    DepartmentMenu
  },
  setup() {
    let state = reactive({
      sysTitle: "",
      selectAll: 0,
      selectOth: 0,
      memCheck: 0,
      clientWidth: '',
      addVisible: false,
      addTab: 0,
      addTitle: '',
      selectVisible: false,
      selectStations: [],
      selectCollects: [],
      selectList: [],
      selectNumbers: [],
      ruleParam: {
        name: '',
        number: '',
        remark: '',
        promoter: '',
      },
    });
    const {t} = useI18n()
    onMounted(() => {
      state.clientWidth = window.innerWidth;
    })

    const confMemRef    = ref();
    const manageMenuRef = ref();

    const handleCloseVisible = (done) => {
      state.ruleParam = {
        name: '',
        number: '',
        remark: '',
        promoter: '',
      };
      done(false);
    }

    const addConf = () => {
      if (!state.selectNumbers.length) {
        ElNotification({
          title: t('失败'),
          message: t('请选择参会成员'),
          type: 'error',
          duration: 2000,
        });
        return;
      }
      state.addVisible = true;
    }

    function receiveChildNum(e) {
      state.department = e;
      confMemRef.value.memberList(state.department, e.id, state.selectList);
    }

    const receiveSelectMem = (e) => {
      if (e.status) {
        if (e.tab === 'station') {
          state.selectStations.push(e.id);
        } else {
          state.selectCollects.push(e.id);
        }
        state.selectList.push(e.id);
        state.selectNumbers.push({
          id: e.id,
          number: e.number,
          name: !e.name || e.name == e.number ? e.number : e.number + '（' + e.name + '）'
        });
      } else {
        let itemKey;
        if (e.tab === 'station') {
          itemKey = state.selectStations.indexOf(e.id);
          if (itemKey > -1) {
            state.selectStations.splice(itemKey, 1);
          }
        } else {
          itemKey = state.selectCollects.indexOf(e.id);
          if (itemKey > -1) {
            state.selectCollects.splice(itemKey, 1);
          }
        }

        let numKey = state.selectList.indexOf(e.id);
        if (numKey > -1) {
          state.selectList.splice(itemKey, 1);
          state.selectNumbers.splice(itemKey, 1);
        }
      }
    }

    const onAddConf = (type) => {
      state.addTab                = type;
      state.selectVisible         = true;
      confMemRef.value.openSelect = true;
      if (type === 1) {
        state.addTitle = t('添加预定义会议');
      } else if (type === 2) {
        state.addTitle = t('立即会议');
      }
    }

    const onCancelConf = () => {
      state.selectStations = [];
      state.selectCollects = [];
      state.selectList     = [];
      state.selectNumbers  = [];
      state.selectVisible  = false;
      state.addVisible     = false;
      state.addTab         = 0;

      confMemRef.value.openSelect = false;
      confMemRef.value.cancelSelect();
    }

    const validateFrom = ref();

    //校验规则
    const rules = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('号码只允许添加数字'),
            trigger: 'blur',
          },],
        promoter: [{
          required: true,
          message: t("请选择主持人"),
          trigger: "change"
        }],

      };
      return rule;
    })

    const onCancelAdd = () => {
      state.addVisible = false;
      state.ruleParam  = {
        name: '',
        number: '',
        remark: '',
        promoter: '',
      };
    }
    const onSubAdd    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            name: state.ruleParam.name,
            number: state.ruleParam.number,
            promoter: state.ruleParam.promoter,
            remark: state.ruleParam.remark,
            stations: state.selectStations,
            collects: state.selectCollects,
          };
          if (state.addTab === 1) {
            addConfManage(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("添加成功"),
                  type: 'success',
                  duration: 2000,
                });
                onCancelConf();
                validateFrom.value.resetFields();
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          } else {
            nowConfManage(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("添加成功"),
                  type: 'success',
                  duration: 2000,
                });

                onCancelConf();
                manageMenuRef.value.openDialog({id: res.data.id});
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };

    return {
      ...toRefs(state),
      onAddConf,
      onCancelConf,
      addConf,
      handleCloseVisible,
      receiveChildNum,
      receiveSelectMem,
      onSubAdd,
      onCancelAdd,
      confMemRef,
      manageMenuRef,
      validateFrom,
      rules,
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.u-conf-content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  flex: 1;
  padding: 10px;
  margin-left: 15px;
  border-left: 1px solid #f0f0f0;
}

.u-conf-item {
  width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.u-conf-item-top {
  border: 1px solid #c8c8c8;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.u-conf-reg {
  background-color: #ecf5ff;
  color: #409eff;
}

.u-conf-reg .u-conf-item-dep {
  background-color: #77b3f4;
  color: #ffffff;
}

.u-conf-reg .u-conf-item-reg {
  margin-left: auto;
}

.u-conf-item-title, .u-conf-item-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
}

.u-conf-item-number {
  margin-left: 5px;
}

.u-conf-item-dep {
  border: 1px solid #c8c8c8;
  border-top: none;
  background: #f0f0f0;
  padding: 3px 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.u-conf-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  align-items: center;
}

.u-conf-btn-title {
  margin-right: 10px;
  color: #40b8fb;
  font-weight: 600;
  font-size: 16px;
}
</style>
