<template>
  <div style="border-left:1px solid #f0f0f0;width:100%;display: flex;flex-direction: column;">
    <div @click="memberList"></div>
    <div style="display: flex;padding-left: 15px;">
      <div style="display: flex;" v-show="openSelect">
        <div style="display: flex;align-items: center;margin-right:15px;">
          <label for="switch-select-all" style="margin-right:5px;">{{ $t('全选') }}:</label>
          <el-switch id="switch-select-all" v-model="selectAll" active-value="1" inactive-value="0"
                     @change="onSelectAll"/>
        </div>
        <div style="display: flex;align-items: center;margin-right:15px;">
          <label for="switch-select-other" style="margin-right:5px;">{{ $t('反选') }}:</label>
          <el-switch id="switch-select-other" v-model="selectOth" active-value="1" inactive-value="0"
                     @change="onSelectOth"/>
        </div>
      </div>
      <div style="margin-left: auto;" v-show="openBtn">
        <el-button size="small" type="primary" @click="onOpenAddCol">
          {{ $t('添加') }}
        </el-button>
        <el-button size="small" type="info" @click="deleteCollects" :disabled="openSelect">
          {{ $t('删除') }}
        </el-button>
      </div>
    </div>
    <div class="u-conf-content-no" v-if="!total">
      {{ $t('无数据') }}...
    </div>
    <div class="u-conf-content">
      <div :class="item.register?'u-conf-item u-conf-reg':'u-conf-item'" v-for="(item) in tableData" :key="item.id">
        <div class="u-conf-item-top">
          <div class="u-conf-item-title">
            <i class="fa fa-fax" v-if="item.tab!=='collect'"></i>
            <i class="fa fa-edit u-conf-col-edit"
               v-if="item.tab==='collect'" @click="onOpenEditCol(item.id)"></i>
            <el-tooltip class="box-item" effect="light" placement="bottom">
              <span class="u-conf-item-number">{{ item.dn }}</span>
              <template #content>{{ item.dn }}</template>
            </el-tooltip>
            <i class="fa fa-registered u-conf-item-reg" v-show="item.register"></i>
          </div>
          <div class="u-conf-item-name">
            <el-checkbox :label="item.display_name?item.display_name:item.dn" value="1" v-model="item.isChecked"
                         @change="onSelectItem(item.id, item.dn, item.display_name, item.tab, item.isChecked)"
                         class="checkbox__inner" v-show="openSelect"></el-checkbox>
            <el-checkbox :label="item.display_name?item.display_name:item.dn" value="1" v-model="item.delChecked"
                         @change="onSelectDel(item.id, item.delChecked)"
                         class="checkbox__inner" v-show="item.tab==='collect' && !openSelect"></el-checkbox>
            <el-tooltip class="box-item" effect="light" placement="bottom">
              <span v-show="item.tab==='station' && !openSelect">{{
                  item.display_name ? item.display_name : item.dn
                }}</span>
              <template #content>{{ item.display_name ? item.display_name : item.dn }}</template>
            </el-tooltip>
          </div>
        </div>
        <div class="u-conf-item-dep">
          <i class="fa fa-map-marker"></i><span class="u-conf-item-number"></span>
        </div>
      </div>
    </div>
    <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   class="mt15" :pager-count="5" :page-count="pageCount" :current-page="pageNum" background
                   :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                   :current-page="pageNum" :total="total" class="mt15"></el-pagination>
    <AddCol ref="addColRef" @refMem="refMem"/>
    <EditCol ref="editColRef" @refMem="refMem"/>
  </div>
</template>
<script>
import {
  reactive, ref,
  toRefs,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  memberDepartment,
} from '@/http/group.api.js';
import {
  batchDelCollect,
} from '@/http/conference.api.js'
import AddCol from "./colAdd.vue";
import EditCol from "./colEdit.vue";

export default {
  name: 'confMem',
  components: {AddCol, EditCol},
  setup(props, context) {
    const {t}   = useI18n()
    const state = reactive({
      tableData: [],
      pageSize: 5, //每页数据条数
      pageNum: 1, //页码
      total: 0, //总数据条数
      pageCount: 0, //总页数
      department: [],
      clientWidth: window.innerWidth,
      memCheck: 0,
      openSelect: false,
      openBtn: false,
      selectAll: '0',
      selectOth: '0',
      selectList: [],
      delList: [],
      nid: '',
    });

    const addColRef     = ref();
    const editColRef    = ref();
    const onOpenAddCol  = () => {
      addColRef.value.openDialog();
    };
    const onOpenEditCol = (id) => {
      editColRef.value.openDialog({id: id});
    };

    function memberList(e, id, selectList) {
      state.department = e;
      state.nid        = id;
      state.selectList = selectList;
      if (id === 'collect') {
        state.openBtn = true;
      } else {
        state.openBtn = false;
      }
      memberLists();
    }

    function memberLists() {
      let params = {
        id: state.department.id,
        page: state.pageNum,
      }
      memberDepartment(params).then(res => {
        state.tableData = res.data.list;
        state.pageCount = res.data.pages;
        state.pageSize  = res.data.length;
        state.total     = res.data.rows;
        state.selectAll = '0';
        state.selectOth = '0';
        if (state.pageSize) {
          state.tableData.forEach(item => {
            if (state.selectList.indexOf(item.id) > -1) {
              item.isChecked = true;
            }
          })
        }
        state.delList = [];
      })
    }

    const refMem = () => {
      memberLists(state);
    }

    //批量删除
    const deleteCollects = () => {
      if (state.delList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的联系人"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该联系人吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.delList
              }
              batchDelCollect(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  memberLists()
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }

    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      memberLists()
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      memberLists()
    };

    const onSelectItem = (id, num, name, t, status) => {
      context.emit('eventIsNum', {id: id, number: num, name: name, tab: t, status: status});
    }

    const onSelectDel = (id, status) => {
      if (status) {
        state.delList.push(id);
      } else {
        let delKey = state.delList.indexOf(id);
        if (delKey > -1) {
          state.delList.splice(delKey, 1);
        }
      }
    }

    const onSelectAll = () => {
      state.tableData.forEach(item => {
        if ((item.isChecked && state.selectAll === '0') || (!item.isChecked && state.selectAll === '1')) {
          context.emit('eventIsNum', {
            id: item.id,
            number: item.dn,
            name: item.display_name,
            tab: item.tab,
            status: !item.isChecked
          });
        }
        item.isChecked = state.selectAll === '1';
      })
    }

    const onSelectOth = () => {
      state.tableData.forEach(item => {
        item.isChecked = !item.isChecked;
        context.emit('eventIsNum', {
          id: item.id,
          number: item.dn,
          name: item.display_name,
          tab: item.tab,
          status: item.isChecked
        });
      })
    }

    const cancelSelect = () => {
      state.selectAll = '0';
      state.selectOth = '0';
      state.tableData.forEach(item => {
        item.isChecked = false;
      })
    }
    return {
      ...toRefs(state),
      addColRef,
      editColRef,
      onOpenAddCol,
      onOpenEditCol,
      deleteCollects,
      onHandleSizeChange,
      onHandleCurrentChange,
      memberList,
      memberLists,
      onSelectItem,
      onSelectDel,
      onSelectAll,
      onSelectOth,
      cancelSelect,
      refMem,
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.u-conf-content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  flex: 1;
  padding: 15px;
}

.u-conf-item {
  width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.u-conf-item-top {
  border: 1px solid #c8c8c8;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.u-conf-reg .u-conf-item-top {
  background-color: #ecf5ff;
  color: #409eff;
}

.u-conf-reg .u-conf-item-dep {
  background-color: #77b3f4;
  color: #ffffff;
}

.u-conf-reg .u-conf-item-reg {
  margin-left: auto;
}

.u-conf-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
}

.u-conf-content-no {
  text-align: center;
  width: 100%;
  padding: 15px;
}

.u-conf-item-number {
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-item-name {
  text-align: center;
  padding: 3px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-item-dep {
  border: 1px solid #c8c8c8;
  border-top: none;
  background: #f0f0f0;
  padding: 3px 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.u-conf-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  align-items: center;
}

.u-conf-btn-title {
  margin-right: 10px;
  color: #40b8fb;
  font-weight: 600;
  font-size: 16px;
}

.u-conf-col-edit {
  color: #409eff;
  cursor: pointer;
  font-size: 16px;
}
</style>
