<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('联系人编辑')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('号码')" prop="number">
              <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="32"
                        onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('备注')">
              <el-input v-model="ruleForm.remark" placeholder="" type="textarea" maxlength="64" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {
  findCollect, putCollect,
} from '@/http/conference.api.js';

export default {
  name: 'colEdit',
  emits: ["print"],
  setup(props, context) {
    const {t}   = useI18n()
    const state = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        name: '',
        number: '',
        remark: '',
      },
      activeName: 'first',
      tabPosition: 'left',
    });

    const initData     = () => {
      let param = {
        id: state.id
      }
      findCollect(param).then(res => {
        if (res.code == 200) {
          state.ruleForm = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      });
    }
    const validateFrom = ref()

    //校验规则
    const rules       = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('号码只允许添加数字'),
            trigger: 'blur',
          },],

      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = (p) => {
      state.isShowDialog = true;
      state.id           = p.id;
      initData();
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            id: state.id,
            name: state.ruleForm.name,
            number: state.ruleForm.number,
            remark: state.ruleForm.remark,
          };
          putCollect(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("refMem");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.name   = '';
      state.ruleForm.number = '';
      state.ruleForm.remark = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}
</style>
