<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('中继')">
          <div class="system-user-search">
            <div style="padding-bottom:10px;">
              <div>
                <el-button size="small" type="primary" @click="onOpenAddMenu">
                  {{ $t('添加') }}
                </el-button>
                <el-button size="small" type="primary" @click="onOpenImport">{{ $t('导入') }}</el-button>
                <el-button size="small" type="info" @click="deleteTrunks">{{ $t('删除') }}</el-button>
              </div>
            </div>
            <div>
              <el-input size="small" v-model="relay.input" :placeholder="$t('中继名称')" prefix-icon="el-icon-search"
                        style="max-width: 180px;margin-right:3px;" @keydown.enter="handleSearch"></el-input>
              <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
            </div>
          </div>
          <el-table :data="relay.tableData" stripe style="width: 100%" class="table" id="out-table"
                    @selection-change="selectionChange" @sort-change="changeTableSort">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column :label="$t('系统状态')">
              <template #default="scope">
                <el-tag size="medium" type="success" v-if="scope.row.status == 1">{{ $t('启用') }}</el-tag>
                <el-tag size="medium" type="info" v-if="scope.row.status == 0">{{ $t('禁用') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('注册状态')">
              <template #default="scope">
                <el-tag size="medium" type="info" v-if="scope.row.register == 0">{{ $t('未注册') }}</el-tag>
                <el-tag size="medium" type="success" v-if="scope.row.register == 1">{{ $t('注册') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
            <el-table-column prop="type" :label="$t('类型')" show-overflow-tooltip width="130">
              <template #default="scope">
                <el-tag size="medium" v-if="scope.row.type == 1">{{ $t('注册中继') }}</el-tag>
                <el-tag size="medium" v-if="scope.row.type == 0">{{ $t('点对点中继') }}</el-tag>
                <el-tag size="medium" v-if="scope.row.type == 2">{{ $t('账号中继') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="domain" :label="$t('域名Ip')" show-overflow-tooltip></el-table-column>
            <el-table-column prop="total_calls" :label="$t('并发数')" show-overflow-tooltip
                             width="90"></el-table-column>
            <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                             sortable="custom" width="180"></el-table-column>
            <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
              <template #default="scope">
                <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
                <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                           class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                         @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                         :page-count="relay.pageCount"
                         :current-page="relay.pageNum" background :page-size="relay.pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="relay.total">
          </el-pagination>
          <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                         :page-size="relay.pageSize" small layout=" prev, pager, next" :page-count="relay.pageCount"
                         :current-page="relay.pageNum"
                         :total="relay.total" class="mt15"></el-pagination>
          <relayAddMenu ref="addMenuRef" @print="print"/>
          <relayEditMenu ref="editMenuRef" @print="print" @changeTag="onOpenImportDod"/>
          <replayImportMenu ref="importMenuRef" @print="print"/>
          <replayImportDod ref="importDodRef" @print="print"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('中继组')">
          <el-table :data="relayGroup.tableData" stripe style="width: 100%;" class="table"
                    id="out-table"
                    @sort-change="changeGroupTableSort">
            <div class="system-user-search">
              <div style="padding-bottom:10px;">
                <div>
                  <el-button size="small" type="primary" @click="onOpenAddGroupMenu">{{ $t('添加') }}
                  </el-button>
                </div>
              </div>
              <div>
                <el-input size="small" v-model="relayGroup.keyword" :placeholder="$t('中继组名称')"
                          prefix-icon="el-icon-search" style="max-width: 180px;margin-right:3px;" @keyup.enter="handleSearchGroup"></el-input>
                <el-button size="small" type="primary" @click="handleSearchGroup">
                  {{ $t('查询') }}
                </el-button>
              </div>
            </div>

            <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('呼叫分配类型')">
              <template #default="scope">
                <el-tag size="medium" type="success" v-if="scope.row.distribution_type == 0">{{ $t('优先级') }}</el-tag>
                <el-tag size="medium" type="info" v-if="scope.row.distribution_type == 1">{{ $t('权重') }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                             sortable="custom" width="180"></el-table-column>
            <el-table-column prop="path" :label="$t('操作')" width="120">
              <template #default="scope">
                <el-button size="mini" type="text" @click="onOpenEditGroupMenu(scope.row)">{{ $t('编辑') }}</el-button>
                <el-button size="mini" type="text" @click="onRowGroupDel(scope.row)"
                           class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleGroupSizeChange"
                         @current-change="onHandleGroupCurrentChange" class="mt15" :pager-count="5"
                         :page-count="relayGroup.pageCount"
                         :current-page="relayGroup.pageNum" background :page-size="relayGroup.pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="relayGroup.total">
          </el-pagination>
          <el-pagination v-else @size-change="onHandleGroupSizeChange" @current-change="onHandleGroupCurrentChange"
                         :page-size="relayGroup.pageSize" small layout=" prev, pager, next"
                         :page-count="relayGroup.pageCount"
                         :current-page="relayGroup.pageNum"
                         :total="relayGroup.total" class="mt15"></el-pagination>
          <AddGroupMenu ref="addGroupMenuRef" @print="print"/>
          <EditGroupMenu ref="editGroupMenuRef" @print="print"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import {onMounted, reactive, ref, toRefs} from 'vue';
import {useI18n} from "vue-i18n";
import relayAddMenu from './components/addMenu.vue';
import relayEditMenu from './components/editMenu.vue';
import replayImportMenu from './components/importMenu.vue';
import replayImportDod from './components/importDod.vue';
import AddGroupMenu from '../group/trunkCom/addMenu';
import EditGroupMenu from '../group/trunkCom/editMenu';
import {batchdelTrunk, deleteTrunk, getTrunk} from '@/http/trunk.api.js'
import {getTrunkGroup, delTrunkGroup} from '@/http/group.api.js';
import {ElMessageBox, ElNotification} from 'element-plus';
import router from "@/router";

export default {
  name: 'relay',
  components: {
    EditGroupMenu,
    AddGroupMenu,
    relayAddMenu,
    relayEditMenu,
    replayImportMenu,
    replayImportDod,
  },
  setup() {
    const {t}       = useI18n()
    let state       = reactive({
      sysTitle: '',
      relay: {
        input: ref(''),
        tableData: [],
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
        sortType: '',
        sortBy: ''
      },
      relayGroup: {
        keyword: ref(''),
        tableData: [],
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
        sortType: '',
        sortBy: ''
      },
      selectionList: [],
      clientWidth: '',
    });
    const blackList = (state) => {
      let params = {
        page: state.pageNum //页码
      }
      getTrunk(params).then(res => {
        if (res.code == 200) {
          state.relay.tableData = res.data.list;
          state.relay.pageCount = res.data.pages;
          state.relay.pageSize  = res.data.length;
          state.relay.total     = res.data.rows;
          state.sysTitle        = res.tag;
          if (res.tag) {
            router.push('/setUp/systemSetup')
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const groupList = (state) => {
      let params = {
        page: state.pageNum,
        keyword: state.keyword,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      getTrunkGroup(params).then(res => {
        if (res.code == 200) {
          state.relayGroup.tableData = res.data.list;
          state.relayGroup.pageCount = res.data.pages;
          state.relayGroup.pageSize  = res.data.length;
          state.relayGroup.total     = res.data.rows;
          state.sysTitle             = res.tag;
          if (res.tag) {
            router.push('/setUp/systemSetup')
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const print     = () => {
      blackList(state);
      groupList(state);
    }
    onMounted(() => {
      blackList(state);
      groupList(state);
      state.clientWidth = window.innerWidth;
    })
    const addMenuRef          = ref();
    const editMenuRef         = ref();
    const importMenuRef       = ref();
    const importDodRef        = ref();
    const addGroupMenuRef     = ref();
    const editGroupMenuRef    = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu       = () => {
      addMenuRef.value.openDialog();
    };
    const onOpenAddGroupMenu  = () => {
      addGroupMenuRef.value.openDialog();
    };
    // 打开编辑菜单弹窗
    const onOpenEditMenu      = (row) => {
      editMenuRef.value.openDialog(row);
    };
    const onOpenEditGroupMenu = (row) => {
      editGroupMenuRef.value.openDialog(row);
    };
    const onOpenImport        = () => {
      importMenuRef.value.openDialog();
    };
    const onOpenImportDod     = () => {
      importDodRef.value.openDialog();
    };
    // 当前行删除
    const onRowDel            = (row) => {
      ElMessageBox.confirm(t("你确定要删除该中继吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteTrunk(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    const onRowGroupDel       = (row) => {
      ElMessageBox.confirm(t("你确定要删除该中继组吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            delTrunkGroup(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                groupList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    const selectionChange     = (list) => {
      state.selectionList = list.map(item => {
        return item.id
      });
    }
    //批量删除
    const deleteTrunks        = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的中继"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该中继吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              batchdelTrunk(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    //搜索
    const handleSearch        = () => {
      let params = {
        length: state.relay.pageSize,
        page: state.relay.pageNum,
        keyword: state.relay.input,
        sort_type: state.relay.sortType,
        sort_by: state.relay.sortBy
      }
      getTrunk(params).then(res => {
        if (res.code == 200) {
          state.relay.tableData = res.data.list;
          state.relay.pageCount = res.data.pages;
          state.relay.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    //搜索
    const handleSearchGroup          = () => {
      let params = {
        length: state.relayGroup.pageSize,
        page: state.relayGroup.pageNum,
        keyword: state.relayGroup.keyword,
        sort_type: state.relayGroup.sortType,
        sort_by: state.relayGroup.sortBy
      }
      getTrunkGroup(params).then(res => {
        if (res.code == 200) {
          state.relayGroup.tableData = res.data.list;
          state.relayGroup.pageCount = res.data.pages;
          state.relayGroup.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const changeTableSort            = (column) => {
      state.relay.sortBy   = column.prop;
      state.relay.sortType = column.order;
      handleSearch()
    }
    const changeGroupTableSort       = (column) => {
      state.relayGroup.sortBy   = column.prop;
      state.relayGroup.sortType = column.order;
      handleSearchGroup()
    }
    // 分页改变
    const onHandleSizeChange         = (val) => {
      state.relay.pageSize = val;
      if (state.relay.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    const onHandleGroupSizeChange    = (val) => {
      state.relayGroup.pageSize = val;
      if (state.relayGroup.keyword !== '') {
        handleSearchGroup();
      } else {
        groupList(state)
      }
    };
    // 分页改变
    const onHandleCurrentChange      = (val) => {
      state.relay.pageNum = val;
      if (state.relay.input !== '') {
        handleSearch();
      } else {
        blackList(state)
      }
    };
    const onHandleGroupCurrentChange = (val) => {
      state.relayGroup.pageNum = val;
      if (state.relayGroup.keyword !== '') {
        handleSearchGroup();
      } else {
        groupList(state)
      }
    };
    return {
      ...toRefs(state),
      onRowDel,
      onRowGroupDel,
      onOpenAddMenu,
      onOpenEditMenu,
      onOpenImport,
      onOpenAddGroupMenu,
      onOpenEditGroupMenu,
      addMenuRef,
      addGroupMenuRef,
      editMenuRef,
      editGroupMenuRef,
      importMenuRef,
      importDodRef,
      onOpenImportDod,
      changeTableSort,
      changeGroupTableSort,
      onHandleSizeChange,
      onHandleGroupSizeChange,
      onHandleCurrentChange,
      onHandleGroupCurrentChange,
      print,
      handleSearch,
      handleSearchGroup,
      selectionChange,
      deleteTrunks,
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}
</style>
