<template>
  <div style="padding-right:15px;height:100%;">
    <el-input size="small" v-model="filterText" :placeholder="$t('查找部门')" prefix-icon="el-icon-search"/>
    <el-scrollbar max-height="460px">
      <el-tree :data="data" node-key="id" :props="defaultProps" :expand-on-click-node="false"
               :filter-node-method="filterNode" :highlight-current="true" ref="tree" @node-click="handleBucketClick">
        <template #default="{ node }">
          <span class="custom-tree-node">
            <span v-if="node.key==='other'" class="u-tree-o">{{ node.label }}</span>
            <span v-if="node.key==='collect'" class="u-tree-c">{{ node.label }}</span>
            <span v-if="node.key!=='collect' && node.key!=='other'">{{ node.label }}</span>
          </span>
        </template>
      </el-tree>
    </el-scrollbar>
  </div>
</template>
<script>
import {reactive, toRefs, onMounted} from 'vue';
import {
  getDepartment, findDepartment
} from '@/http/group.api.js';
import {useI18n} from "vue-i18n";

export default {
  name: 'confDep',
  setup(props, context) {
    const {t}   = useI18n()
    const state = reactive({
      id: '',
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      department: {
        id: '',
        name: '',
      },
    });
    const print = () => {
      blackList(state);
    }
    onMounted(() => {
      blackList(state);
    })

    const blackList = (state) => {
      getDepartment().then(res => {
        state.data = res.data;
        state.data.push({
          id: 'other',
          level: 0,
          name: t('未分配分机'),
          children: [],
          disabled: false,
        });
        state.data.push({
          id: 'collect',
          level: 0,
          name: t('自建联系人'),
          children: [],
          disabled: false,
        });
        if (res.data.length) {
          state.id   = state.data[0].id;
          let params = {
            id: state.id
          }
          findDepartment(params).then(res => {
            state.department = res.data;
            context.emit('eventIsNum', {id: state.id, name: state.data[0].name});
          })
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    const handleBucketClick = (v) => {
      state.id = v.id;
      if (v.id === 'other' || v.id === 'collect') {
        context.emit('eventIsNum', {
          id: v.id,
          name: v.name
        });
      } else {
        let params = {
          id: state.id
        }
        findDepartment(params).then(res => {
          state.department = res.data;
          context.emit('eventIsNum', state.department);
        })
      }
    };
    const memberData        = (id) => {
      let params = {
        id: id
      }
      findDepartment(params).then(res => {
        state.department = res.data;
        context.emit('eventIsNum', state.department);
      })
    }
    return {
      ...toRefs(state),
      handleBucketClick,
      print,
      memberData,
    }
  },
  data() {
    return {}
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
  },
}
</script>
<style scoped>
.el-tree {
  margin-top: 15px;
}

.el-tree :deep(.el-tree-node__content) {
  height: 40px !important;
}

.el-tree :deep(.el-tree-node__expand-icon) {
  font-size: 15px !important;
}

.custom-tree-node {
  font-size: 17px;
}

.u-tree-c {
  color: #409eff;
}

.u-tree-o {
  color: #f56c6c;
}
</style>
