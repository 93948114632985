<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增总机')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('参数设置')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="110px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('类型')" class="" prop="type">
                  <el-select v-model="ruleForm.type" :placeholder="$t('类型')">
                    <el-option :label="$t('普通总机')" value="0">{{ $t('普通总机') }}</el-option>
                    <el-option :label="$t('直拨总机')" value="1">{{ $t('直拨总机') }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('按键等待')" prop="timeout">
                  <el-input v-model="ruleForm.timeout" :placeholder="$t('按键等待')"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable maxlength="3"
                            show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('按键间隔')" prop="inter_digit_timeout">
                  <el-input v-model="ruleForm.inter_digit_timeout" :placeholder="$t('按键间隔')"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable maxlength="3"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('按键失败')" prop="max_failures">
                  <el-input v-model="ruleForm.max_failures" :placeholder="$t('按键失败')"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable maxlength="3"
                            show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('超时次数')" prop="max_timeouts">
                  <el-input v-model="ruleForm.max_timeouts" :placeholder="$t('超时次数')"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable maxlength="3"
                            show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
              <!--							<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--								<el-form-item :label="$t('状态')">-->
              <!--									<el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0"></el-switch>-->
              <!--								</el-form-item>-->
              <!--							</el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type == 1">
                <el-form-item :label="$t('直拨分机')" prop="">
                  <el-input v-model="ruleForm.ivr_station" placeholder="" maxlength="8" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('语音选项')" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('欢迎语')" class="">
                  <el-select v-model="ruleForm.greet_long" :placeholder="$t('提示音')">
                    <el-option v-for="(item,index) in media" :key="index" :label="item.name" :value="item.id"
                               @click="playMusic1(index)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('试听')" class="">
                  <div class="footer">
                    <audio :src="ruleForm.musicUrl1.url" controls></audio>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('菜单提示音')" class="">
                  <el-select v-model="ruleForm.greet_short" :placeholder="$t('提示音')">
                    <el-option v-for="(item,index) in media" :key="index" :label="item.name" :value="item.id"
                               @click="playMusic2(index)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('试听')" class="">
                  <div class="footer">
                    <audio :src="ruleForm.musicUrl2.url" controls></audio>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('无效提示音')" class="">
                  <el-select v-model="ruleForm.invalid_sound" :placeholder="$t('提示音')">
                    <el-option v-for="(item,index) in media" :key="index" :label="item.name" :value="item.id"
                               @click="playMusic3(index)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('试听')" class="">
                  <div class="footer">
                    <audio :src="ruleForm.musicUrl3.url" controls></audio>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('退出提示音')" class="">
                  <el-select v-model="ruleForm.exit_sound" :placeholder="$t('提示音')">
                    <el-option v-for="(item,index) in media" :key="index" :label="item.name" :value="item.id"
                               @click="playMusic4(index)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('试听')" class="">
                  <div class="footer">
                    <audio :src="ruleForm.musicUrl4.url" controls></audio>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('主叫回拨')">
                  <el-switch v-model="ruleForm.cid_tracer" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="ivr-check">
                <el-form-item :label="$t('主叫追踪')">
                  <el-checkbox v-model="ruleForm.cid_tracer_callback" border true-label="1" false-label="0">
                  </el-checkbox>
                  <el-select v-model="ruleForm.cid_expire" :disabled="ruleForm.cid_tracer_callback != 1"
                             :placeholder="$t('请选择主叫追踪时间')" filterable allow-create default-first-option
                             style="width:85%;">
                    <el-option :label="$t('当天')" value="0"></el-option>
                    <el-option :label="$t('一天')" value="1"></el-option>
                    <el-option :label="$t('两天')" value="2"></el-option>
                    <el-option :label="$t('三天')" value="3"></el-option>
                    <el-option :label="$t('四天')" value="4"></el-option>
                    <el-option :label="$t('五天')" value="5"></el-option>
                    <el-option :label="$t('六天')" value="6"></el-option>
                    <el-option :label="$t('七天')" value="7"></el-option>
                    <el-option :label="$t('三十天')" value="30"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('按键设置')" name="third">
          <el-form :model="ruleForm" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple-light">
                  <el-table :data="form.callingNumber" stripe style="width: 100%;margin-top:15px;" class="table"
                            id="out-table" :cell-click="rowr">
                    <el-table-column prop="menu" :label="$t('菜单')" show-overflow-tooltip min-width="110">
                      <template #default="scope">
                        <el-select v-model="scope.row.menu" placeholder="" allow-create @click="rowr(scope.$index)"
                                   @change="menuClick">
                          <el-option :label="$t('重听')" value="0"></el-option>
                          <el-option :label="$t('菜单')" value="2"></el-option>
                          <el-option :label="$t('转分机')" value="1"></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="keynumber" :label="$t('按键')" show-overflow-tooltip min-width="110">
                      <template #default="scope">
                        <el-input class="item" v-model="scope.row.keynumber" :placeholder="$t('请输入内容')"
                                  :disabled="scope.row.menu!='0'&&scope.row.menu!='2'"
                                  onkeyup="value=value.replace(/[^\*\#\d]/g,'')" maxlength="6"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="transfer" :label="$t('目的')" show-overflow-tooltip min-width="110">
                      <template #default="scope">
                        <el-select v-model="scope.row.transfer" :placeholder="$t('请选择')" allow-create
                                   :disabled="scope.row.menu!='2'">
                          <el-option :label="$t('转其他号码')" value="0"></el-option>
                          <el-option :label="$t('转Ivr')" value="1"></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="destination" :label="$t('目的号码')" show-overflow-tooltip min-width="110">
                      <template #default="scope">
                        <el-input class="item" v-model="scope.row.destination" :placeholder="$t('请输入内容')"
                                  :disabled="scope.row.menu!='2'" maxlength="32"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="path" width="90">
                      <template #default="scope">
                        <el-button size="mini" type="text" v-if="scope.$index+1 == form.callingNumber.length"
                                   @click="addItem(scope.$index)" :title="$t('添加')">
                          <el-icon :size="16">
                            <CirclePlus/>
                          </el-icon>
                        </el-button>
                        <el-button size="mini" type="text" @click="DelcallingNumber(scope.$index)"
                                   v-if="scope.$index !== 0" :title="$t('删除')" style="margin-left: 0px !important;">
                          <el-icon :size="16" color="#f63d3d">
                            <Delete/>
                          </el-icon>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addIvr
} from '@/http/ivr.api.js';
import {
  simpleMedia,
} from '@/http/media.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'switchboardAddMenu',
  emits: ['print'],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        type: '0',
        status: 0,
        timeout: '10',
        inter_digit_timeout: '5',
        max_failures: '3',
        max_timeouts: '3',
        greet_long: '',
        greet_short: '',
        invalid_sound: '',
        exit_sound: '',
        ivr_type: 0,
        ivr_station: '',
        cid_tracer: 0,
        cid_tracer_callback: 0,
        cid_expire: '',
        musicUrl1: '',
        musicUrl2: '',
        musicUrl3: '',
        musicUrl4: '',
      },
      activeName: 'first',
      tabPosition: 'left',
      form: {
        callingNumber: [{
          keynumber: '',
          transfer: '',
          destination: '',
          menu: '',
        }],
      },
      index: '',
      media: [],
    });
    const validateFrom = ref();
    //校验规则
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
        timeout: [{
          required: true,
          message: t("请输入按键等待时间"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('按键等待时间格式错误'),
            trigger: 'blur',
          }],
        inter_digit_timeout: [{
          required: true,
          message: t("请输入按键间隔时间"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('按键间隔时间格式错误'),
            trigger: 'blur',
          }],
        max_failures: [{
          required: true,
          message: t("请输入按键失败次数"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('按键失败次数格式错误'),
            trigger: 'blur',
          }],
        max_timeouts: [{
          required: true,
          message: t("请输入超时次数"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('超时次数格式错误'),
            trigger: 'blur',
          }],
      };
      return rule;
    })

    //获取菜单当前行
    function rowr(index) {
      state.index = index
    }

    const menuClick = (val) => {
      let index = state.index;
      if (val == 0) {
        state.form.callingNumber[index].transfer    = '';
        state.form.callingNumber[index].destination = '';
      } else if (val == 1) {
        state.form.callingNumber[index].keynumber   = '';
        state.form.callingNumber[index].destination = '';
        state.form.callingNumber[index].transfer    = '';
      }
    }

    // 打开弹窗
    const openDialog       = () => {
      state.isShowDialog = true;
      state.activeName   = 'first';
      initForm();
      simpleMedia().then(res => {
        state.media = res.data;
      }).catch(err => {
        console.log(err, 'err');
      })
    };
    // 关闭弹窗
    const closeDialog      = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel         = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit         = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          if (state.form.callingNumber[state.form.callingNumber.length - 1].menu != '1') {
            if (/^\d+$/.test(state.form.callingNumber[state.form.callingNumber.length - 1].menu)) {
              let keynumberRule = /^[*#0-9]$/;
              if (!keynumberRule.test(state.form.callingNumber[state.form.callingNumber.length - 1].keynumber)) {
                ElNotification({
                  title: t('失败'),
                  message: t('按键格式错误'),
                  type: 'error',
                  duration: 2000,
                });
              } else {
                const params = {
                  name: state.ruleForm.name,
                  type: state.ruleForm.type,
                  timeout: state.ruleForm.timeout,
                  inter_digit_timeout: state.ruleForm.inter_digit_timeout,
                  max_failures: state.ruleForm.max_failures,
                  max_timeouts: state.ruleForm.max_timeouts,
                  status: state.ruleForm.status,
                  greet_long: state.ruleForm.greet_long,
                  greet_short: state.ruleForm.greet_short,
                  invalid_sound: state.ruleForm.invalid_sound,
                  exit_sound: state.ruleForm.exit_sound,
                  ivr_type: state.ruleForm.type,
                  ivr_station: state.ruleForm.ivr_station,
                  cid_tracer: state.ruleForm.cid_tracer,
                  cid_tracer_callback: state.ruleForm.cid_tracer_callback,
                  cid_expire: state.ruleForm.cid_expire,
                  menu: state.form.callingNumber,
                };
                addIvr(params).then(res => {
                  if (res.code == 200) {
                    ElNotification({
                      title: t("成功"),
                      message: t("添加成功"),
                      type: 'success',
                      duration: 2000,
                    });
                    validateFrom.value.resetFields();
                    state.isShowDialog = false;
                    context.emit("print");
                  } else {
                    ElNotification({
                      title: t('失败'),
                      message: res.msg,
                      type: 'error',
                      duration: 2000,
                    });
                  }
                })
              }
            } else {
              state.form.callingNumber.pop();
              const params = {
                name: state.ruleForm.name,
                type: state.ruleForm.type,
                timeout: state.ruleForm.timeout,
                inter_digit_timeout: state.ruleForm.inter_digit_timeout,
                max_failures: state.ruleForm.max_failures,
                max_timeouts: state.ruleForm.max_timeouts,
                status: state.ruleForm.status,
                greet_long: state.ruleForm.greet_long,
                greet_short: state.ruleForm.greet_short,
                invalid_sound: state.ruleForm.invalid_sound,
                exit_sound: state.ruleForm.exit_sound,
                cid_tracer: state.ruleForm.cid_tracer,
                cid_tracer_callback: state.ruleForm.cid_tracer_callback,
                cid_expire: state.ruleForm.cid_expire,
                menu: state.form.callingNumber,
              };
              addIvr(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("添加成功"),
                    type: 'success',
                    duration: 2000,
                  });
                  validateFrom.value.resetFields();
                  state.isShowDialog = false;
                  context.emit("print");
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          } else {
            const params = {
              name: state.ruleForm.name,
              type: state.ruleForm.type,
              timeout: state.ruleForm.timeout,
              inter_digit_timeout: state.ruleForm.inter_digit_timeout,
              max_failures: state.ruleForm.max_failures,
              max_timeouts: state.ruleForm.max_timeouts,
              status: state.ruleForm.status,
              greet_long: state.ruleForm.greet_long,
              greet_short: state.ruleForm.greet_short,
              invalid_sound: state.ruleForm.invalid_sound,
              exit_sound: state.ruleForm.exit_sound,
              cid_tracer: state.ruleForm.cid_tracer,
              cid_tracer_callback: state.ruleForm.cid_tracer_callback,
              cid_expire: state.ruleForm.cid_expire,
              menu: state.form.callingNumber,
            };
            addIvr(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("添加成功"),
                  type: 'success',
                  duration: 2000,
                });
                validateFrom.value.resetFields();
                state.isShowDialog = false;
                context.emit("print");
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm         = () => {
      state.ruleForm.name                = '';
      state.ruleForm.type                = '0';
      state.ruleForm.status              = 0;
      state.ruleForm.timeout             = '10';
      state.ruleForm.inter_digit_timeout = '5';
      state.ruleForm.max_failures        = '3';
      state.ruleForm.max_timeouts        = '3';
      state.ruleForm.greet_long          = '';
      state.ruleForm.greet_short         = '';
      state.ruleForm.invalid_sound       = '';
      state.ruleForm.exit_sound          = '';
      state.ruleForm.ivr_type            = 0;
      state.ruleForm.ivr_station         = '';
      state.ruleForm.cid_tracer          = 0;
      state.ruleForm.cid_tracer_callback = 0;
      state.ruleForm.cid_expire          = '';
      state.ruleForm.musicUrl1           = '';
      state.ruleForm.musicUrl2           = '';
      state.ruleForm.musicUrl3           = '';
      state.ruleForm.musicUrl4           = '';
      state.form.callingNumber           = [{
        keynumber: '',
        transfer: '',
        destination: '',
        menu: '',
      }]
    };
    //提示音
    const playMusic1       = (res) => {
      state.ruleForm.musicUrl1 = state.media[res];
    }
    const playMusic2       = (res) => {
      state.ruleForm.musicUrl2 = state.media[res];
    }
    const playMusic3       = (res) => {
      state.ruleForm.musicUrl3 = state.media[res];
    }
    const playMusic4       = (res) => {
      state.ruleForm.musicUrl4 = state.media[res];
    }
    //按键设置表格删除
    const DelcallingNumber = (index) => {
      state.form.callingNumber.splice(index, 1);
    }
    //按键设置表格添加
    const addItem          = (index) => {
      if (state.form.callingNumber[index].menu != '1') {
        if (!isNaN(state.form.callingNumber[index].menu)) {
          let keynumberRule = /^[*#0-9]$/;
          if (!keynumberRule.test(state.form.callingNumber[index].keynumber)) {
            ElNotification({
              title: t('失败'),
              message: t('按键格式错误'),
              type: 'error',
              duration: 2000,
            });
          } else {
            state.form.callingNumber.push({
              keynumber: '',
              transfer: '',
              destination: '',
              menu: '',

            });
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: t('请完整填写按键设置'),
            type: 'error',
            duration: 2000,
          });
        }

      } else {
        state.form.callingNumber.push({
          keynumber: '',
          transfer: '',
          destination: '',
          menu: '',

        });
      }
    }
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      playMusic1,
      playMusic2,
      playMusic3,
      playMusic4,
      DelcallingNumber,
      addItem,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
      rowr,
      menuClick,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.item .el-input__inner {
  height: 40px !important;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-checkbox.is-bordered {
  padding: 4px 8px 4px 8px;
  height: 32px;
}

</style>
