<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增呼出路由')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <!-- <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="基本" name="first"> -->
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('状态')" prop="status">
              <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('目标类型')" prop="target_type">
              <el-radio-group v-model="ruleForm.target_type" @change="onType">
                <el-radio label="0">{{ $t('中继') }}</el-radio>
                <el-radio label="1">{{ $t('中继组') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="ruleForm.target_type == 0?$t('中继'):$t('中继组')" prop="target_id">
              <el-select v-model="ruleForm.target_id" filterable clearable>
                <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('外呼权限')" prop="call_authority">
              <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择外呼权限')" clearable>
                <el-option :label="$t('禁止')" value="0"></el-option>
                <el-option :label="$t('局内')" value="1"></el-option>
                <el-option :label="$t('本地')" value="2"></el-option>
                <el-option :label="$t('国内')" value="4"></el-option>
                <el-option :label="$t('国际权限')" value="8"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">-->
<!--            <el-tooltip class="box-item" effect="dark" placement="bottom">-->
<!--              <template #content>-->
<!--                {{ $t('特殊字符定义如下') }}:<br/>-->
<!--                {{ $t('X代表从0到9的任意数字') }}；<br/>-->
<!--                {{ $t('Z代表从1到9的任意数字') }}；<br/>-->
<!--                {{ $t('N代表从2到9的任意数字') }}；<br/>-->
<!--                [12345-9] <br/>-->
<!--                {{ $t('代表括内任数字例如在此例中代表号') }}<br/>-->
<!--                {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>-->
<!--                {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>-->
<!--                {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>-->
<!--                {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>-->
<!--                {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>-->
<!--              </template>-->
<!--              <img src="/static/img/tip.png" class="tip"/>-->
<!--            </el-tooltip>-->
<!--            <el-form-item :label="$t('匹配模式')" class="pipei">-->
<!--              <el-input v-model="ruleForm.pattern" :placeholder="$t('匹配模式')" maxlength="32" clearable></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('优先级')" prop="priority">
              <el-input-number v-model="ruleForm.priority" :placeholder="$t('请输入优先级')" clearable :min="0"
                               :max="999" maxlength="3"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('时间条件')">
              <el-select v-model="ruleForm.time_conditions" :placeholder="$t('请选择时间条件')" multiple>
                <el-option v-for="item in times" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
          <!--            <el-form-item label="密码类型">-->
          <!--              <el-select v-model="ruleForm.pwd_type" placeholder="" filterable allow-create default-first-option>-->
          <!--                <el-option label="无" value="0"></el-option>-->
          <!--                <el-option label="PIN列表" value="1"></el-option>-->
          <!--                <el-option label="单独密码" value="2"></el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
          <!--            <el-form-item :label="ruleForm.pwd_type==1?'PIN列表':'密码'">-->
          <!--              <el-input v-model="ruleForm.pwd_str" placeholder="" :disabled="ruleForm.pwd_type==0" clearable></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20" v-show="musicUrl1 !== '2'">-->
          <!--            <el-form-item label="循环抓取">-->
          <!--              <el-switch v-model="ruleForm.cir_status"></el-switch>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
      </el-form>
      <!-- </el-tab-pane>
        <el-tab-pane label="改编来电号码" name="fifth"> -->
      <!-- <p>配置相应的模式来重新格式化呼入和呼出的号码。</p> -->
      <!-- <el-form :model="ruleForm" size="small" label-width="90px">
            <el-card shadow="never">
              <template #header>
                <div class="card-header">
                  <h3 style="display:inline-block">主叫号码变换</h3>
                  <el-button type="primary" icon="el-icon-plus" size="small" @click="addcallingNumber">添加</el-button>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="grid-content bg-purple-light">
                    <el-table :data="form.callingNumber" stripe style="width: 100%;margin-top:15px;" class="table"
                      id="out-table" @cell-mouse-enter="handleCellEnter" @cell-mouse-leave="handleCellLeave"
                      :row-class-name="tableRowIndex">
                      <el-table-column type="selection" width="55"> </el-table-column>
                      <el-table-column prop="sourceModule" label="源模式" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.sourceModule"
                            placeholder="请输入内容"></el-input>
                          <div v-else class="txt">{{scope.row.sourceModule}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="replaceMode" label="替换模式" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.replaceMode"
                            placeholder="请输入内容"></el-input>
                          <div v-else class="txt">{{scope.row.replaceMode}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="path" label="操作" width="90">
                        <template #default="scope">
                          <el-button size="mini" type="text" @click="DelcallingNumber(scope.$index)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <el-card shadow="never">
              <template #header>
                <div class="card-header">
                  <h3 style="display:inline-block">被叫号码变换</h3>
                  <el-button type="primary" icon="el-icon-plus" size="small" @click="cddcalledNumber">添加</el-button>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="grid-content bg-purple-light">
                    <el-table :data="form.calledNumber" stripe style="width: 100%;margin-top:15px;" class="table"
                      id="out-table" @cell-mouse-enter="BhandleCellEnter" @cell-mouse-leave="BhandleCellLeave"
                      :row-class-name="calledNumberRowIndex">
                      <el-table-column type="selection" width="55"> </el-table-column>
                      <el-table-column prop="sourceModule" label="源模式" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.sourceModule"
                            placeholder="请输入内容"></el-input>
                          <div v-else class="txt">{{scope.row.sourceModule}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="replaceMode" label="替换模式" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.replaceMode"
                            placeholder="请输入内容"></el-input>
                          <div v-else class="txt">{{scope.row.replaceMode}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="path" label="操作" width="90">
                        <template #default="scope">
                          <el-button size="mini" type="text" @click="DelcalledNumber(scope.$index)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-form>
        </el-tab-pane>
      </el-tabs> -->
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  simpleTrunk,
  trunkTarget,
} from '@/http/trunk.api.js';
import {
  addRouteout,
} from '@/http/routeout.api.js';
import {
  ElNotification
} from 'element-plus';
import {timeSimple} from "@/http/routein.api";

function routeoutEditData(state) {
  simpleTrunk().then(res => {
    state.trunk = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

function timeSimList(state) {
  timeSimple().then(res => {
    state.times = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'incomingCallAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}              = useI18n()
    const state            = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        status: 0,
        target_type: '0',
        target_id: '',
        from: '',
        input: 'SIP',
        call_authority: '',
        pattern: '',
        priority: '',
        pwd_type: '0',
        pwd_str: '',
        cir_status: 0,
        time_conditions: [],
      },
      activeName: 'first',
      tabPosition: 'left',
      trunk: [],
      times: [],
      form: {
        callingNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
        calledNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
      },
      value: '',
      balue: '',
    });
    const validateFrom     = ref()
    //校验规则
    const rules            = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: t("请输入优先级"),
          trigger: "blur"
        }],
        target_type: [{
          required: true,
          message: t("请选择目标类型"),
          trigger: "change"
        }],
        target_id: [{
          required: true,
          message: t("请选择中继或中继组"),
          trigger: "change"
        }],
        call_authority: [{
          required: true,
          message: t("请选择外呼权限"),
          trigger: "change"
        }],
        status: [{
          required: true,
          message: t("请选择状态"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog       = () => {
      state.isShowDialog = true;
      timeSimList(state)
      routeoutEditData(state)
    };
    // 关闭弹窗
    const closeDialog      = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel         = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit         = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            target_type: state.ruleForm.target_type,
            target_id: state.ruleForm.target_id,
            priority: state.ruleForm.priority,
            pattern: state.ruleForm.pattern,
            call_authority: state.ruleForm.call_authority,
            time_conditions: state.ruleForm.time_conditions,
          };
          addRouteout(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm         = () => {
      state.ruleForm.name            = '';
      state.ruleForm.status          = 0;
      state.ruleForm.target_type     = '0';
      state.ruleForm.target_id       = '';
      state.ruleForm.from            = '';
      state.ruleForm.input           = 'SIP';
      state.ruleForm.call_authority  = '';
      state.ruleForm.pattern         = '';
      state.ruleForm.priority        = '';
      state.ruleForm.pwd_type        = '0';
      state.ruleForm.pwd_str         = '';
      state.ruleForm.cir_status      = 0;
      state.ruleForm.time_conditions = [];
    };
    //DID表单添加
    const addItem          = () => {
      state.form.dynamicItem.push({
        did: "",
        dnis: "",
        isEdit: false
      });
    }
    //DID表单删除
    const deleteItem       = (index) => {
      state.form.dynamicItem.splice(index, 1);
    }
    //主叫号码添加
    const addcallingNumber = () => {
      state.form.callingNumber.push({
        sourceModule: "1",
        replaceMode: "1",
        isEdit: false
      });
    }
    //主叫号码删除
    const DelcallingNumber = (index) => {
      state.form.callingNumber.splice(index, 1);
    }

    //主叫鼠标移入cell
    function handleCellEnter(row) {
      let index                              = row.defineIndex;
      state.form.callingNumber[index].isEdit = true
    }

    // 主叫鼠标移出cell
    function handleCellLeave(row) {
      let index                              = row.defineIndex;
      state.form.callingNumber[index].isEdit = false
    }

    //获取到每一行的index
    function tableRowIndex({
                             row,
                             rowIndex
                           }) {
      row.defineIndex = rowIndex
    }

    //被叫号码添加
    const cddcalledNumber = () => {
      state.form.calledNumber.push({
        sourceModule: "1",
        replaceMode: "1"
      });
    }
    //被叫号码删除
    const DelcalledNumber = (index) => {
      state.form.calledNumber.splice(index, 1);
    }

    //被叫鼠标移入cell
    function BhandleCellEnter(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = true
    }

    // 被叫鼠标移出cell
    function BhandleCellLeave(row) {
      let index                             = row.defineIndex;
      state.form.calledNumber[index].isEdit = false
    }

    //获取到每一行的index
    function calledNumberRowIndex({
                                    row,
                                    rowIndex
                                  }) {
      row.defineIndex = rowIndex
    }

    const onType = (row) => {
      let param = {
        type: row,
      }
      trunkTarget(param).then(res => {
        if (res.code == 200) {
          state.trunk = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      validateFrom,
      addItem,
      deleteItem,
      addcallingNumber,
      DelcallingNumber,
      handleCellEnter,
      handleCellLeave,
      tableRowIndex,
      onType,
      initForm,
      ...toRefs(state),
      cddcalledNumber,
      DelcalledNumber,
      BhandleCellEnter,
      BhandleCellLeave,
      calledNumberRowIndex,
      rules,
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.tip {
  float: left;
  position: relative;
  top: 9px !important;
  left: -3px !important;
}
</style>
