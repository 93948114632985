<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑呼出路由')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('状态')" prop="status">
                  <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('目标类型')" prop="target_type">
                  <el-radio-group v-model="ruleForm.target_type" @change="onType">
                    <el-radio :label="0" :value="0">{{ $t('中继') }}</el-radio>
                    <el-radio :label="1" :value="1">{{ $t('中继组') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="ruleForm.target_type == 0?$t('中继'):$t('中继组')" prop="target_id">
                  <el-select v-model="ruleForm.target_id" filterable clearable>
                    <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('外呼权限')" prop="call_authority">
                  <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择外呼权限')">
                    <el-option :label="$t('禁止')" :value="0"></el-option>
                    <el-option :label="$t('局内')" :value="1"></el-option>
                    <el-option :label="$t('本地')" :value="2"></el-option>
                    <el-option :label="$t('国内')" :value="4"></el-option>
                    <el-option :label="$t('国际权限')" :value="8"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">-->
              <!--                <el-tooltip class="box-item" effect="dark" placement="bottom">-->
              <!--                  <template #content>-->
              <!--                    {{ $t('特殊字符定义如下') }}:<br/>-->
              <!--                    {{ $t('X代表从0到9的任意数字') }}；<br/>-->
              <!--                    {{ $t('Z代表从1到9的任意数字') }}；<br/>-->
              <!--                    {{ $t('N代表从2到9的任意数字') }}；<br/>-->
              <!--                    [12345-9] <br/>-->
              <!--                    {{ $t('代表括内任数字例如在此例中代表号') }}<br/>-->
              <!--                    {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>-->
              <!--                    {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>-->
              <!--                    {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>-->
              <!--                    {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>-->
              <!--                    {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>-->
              <!--                  </template>-->
              <!--                  <img src="/static/img/tip.png" class="tip"/>-->
              <!--                </el-tooltip>-->
              <!--                <el-form-item :label="$t('匹配模式')" class="pipei">-->
              <!--                  <el-input v-model="ruleForm.pattern" :placeholder="$t('匹配模式')" maxlength="32"-->
              <!--                            clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('优先级')" prop="priority">
                  <el-input-number v-model="ruleForm.priority" :placeholder="$t('请输入优先级')" clearable :min="0"
                                   :max="999" maxlength="3"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('时间条件')">
                  <el-select v-model="ruleForm.time_conditions" :placeholder="$t('请选择时间条件')" multiple>
                    <el-option v-for="item in times" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item label="密码">-->
              <!--                  <el-select v-model="ruleForm.pwd_type" placeholder="" filterable allow-create default-first-option>-->
              <!--                    <el-option label="无" value="0"></el-option>-->
              <!--                    <el-option label="PIN列表" value="1"></el-option>-->
              <!--                    <el-option label="单独密码" value="2"></el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="ruleForm.pwd_type==1?'PIN列表':'密码'">-->
              <!--                  <el-input v-model="ruleForm.pwd_str" placeholder="" clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item label="循环抓取">-->
              <!--                  <el-switch v-model="ruleForm.cir_status"></el-switch>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
            </el-row>
            <span class="dialog-footer" style="float:right">
							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
							<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
						</span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('号码变换')" name="fifth">
          <p>{{ $t('配置相应的模式来重新格式化呼出的号码') }}</p>
          <el-form :model="ruleForm" size="small" label-width="90px">
            <el-card shadow="never" class="mb10">
              <template #header>
                <div class="card-header u-el-button-small">
                  <el-tooltip class="box-item" effect="dark" placement="bottom">
                    <h3>{{ $t('被叫号码变换') }}
                      <el-icon size="small" color="#409eff">
                        <info-filled/>
                      </el-icon>
                    </h3>
                    <template #content>
                      {{ $t('特殊字符定义如下') }}:<br/>
                      {{ $t('X代表从0到9的任意数字') }}；<br/>
                      {{ $t('Z代表从1到9的任意数字') }}；<br/>
                      {{ $t('N代表从2到9的任意数字') }}；<br/>
                      [12345-9] <br/>
                      {{ $t('代表括内任数字例如在此例中代表号') }}<br/>
                      {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>
                      {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>
                      {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>
                    </template>
                  </el-tooltip>
                  <el-button type="primary" size="small" @click="addCalleeMode">
                    <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                      <CirclePlus/>
                    </el-icon>
                    <span style="vertical-align: middle">{{ $t('添加') }}</span>
                  </el-button>
                </div>
              </template>
              <el-row :gutter="35">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="grid-content bg-purple-light">
                    <el-table :data="form.calleeMode" stripe style="width: 100%;margin-top:15px;" class="table"
                              id="out-table" @cell-mouse-enter="BhandleCellEnter" @cell-mouse-leave="BhandleCellLeave"
                              :row-class-name="calledNumberRowIndex">
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column prop="name" :label="$t('匹配模式')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.mode"
                                    :placeholder="$t('请输入内容')" maxlength="32">
                          </el-input>
                          <div v-else class="txt">{{ scope.row.mode }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" :label="$t('删除前置位数')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.remove"
                                    :placeholder="$t('请输入内容')" onkeyup="value=value.replace(/[^\d]/g,'')"
                                    maxlength="3"></el-input>
                          <div v-else class="txt">{{ scope.row.remove }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="value" :label="$t('前置号码')" show-overflow-tooltip>
                        <template #default="scope">
                          <el-input v-if="scope.row.isEdit" class="item" v-model="scope.row.number"
                                    :placeholder="$t('请输入内容')" onkeyup="value=value.replace(/[^\+\d]/g,'')"
                                    maxlength="32">
                          </el-input>
                          <div v-else class="txt">{{ scope.row.number }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="path" :label="$t('操作')" width="90">
                        <template #default="scope">
                          <el-button size="mini" type="text" @click="delCalleeMode(scope.$index)" :title="$t('删除')">
                            <el-icon :size="16" color="#f63d3d">
                              <Delete/>
                            </el-icon>
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <span class="dialog-footer" style="float:right">
              <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="onSubSec" size="small">{{ $t('保存') }}</el-button>
            </span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('呼出路由成员')" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p class="member">{{ $t('分机成员') }}</p>
                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'dn',}" :data="leftValue" filterable
                             :titles="[$t('可用分机'), $t('成员分机')]" :button-texts="[$t('移除'), $t('添加')]"
                             :filter-placeholder="$t('搜索分机')" @change="getObject">
                </el-transfer>
              </el-col>
            </el-row>
            <!--						<span class="dialog-footer" style="float:right">-->
            <!--							<el-button @click="onCancel" size="small">{{$t('取消')}}</el-button>-->
            <!--							<el-button type="primary" size="small">{{$t('保存')}}</el-button>-->
            <!--						</span>-->
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  trunkTarget,
} from '@/http/trunk.api.js';
import {
  findRouteout,
  updateRouteout,
  deleteRouteoutMember,
  routeoutMember,
  setTransformRouteout
} from '@/http/routeout.api.js';
import {
  avaliableStation
} from '@/http/extension.api.js';
import {
  ElNotification
} from 'element-plus';
import {timeSimple} from "@/http/routein.api";

function timeSimList(state) {
  timeSimple().then(res => {
    state.times = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'incomingCalleditMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}              = useI18n()
    const state            = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        name: '',
        status: '',
        target_type: '0',
        target_id: '',
        from: '',
        input: 'SIP',
        call_authority: '',
        pattern: '',
        priority: '',
        pwd_type: '0',
        pwd_str: '',
        cir_status: 0,
        time_conditions: [],
      },
      activeName: 'first',
      tabPosition: 'left',
      trunk: [],
      times: [],
      form: {
        calleeMode: [],
      },
      value: '',
      rightValue: [],
      leftValue: [],
      editLayoutData: [],
    });
    const routeoutEditData = (state) => {
      let params = {
        id: state.id
      }
      findRouteout(params).then(res => {
        state.ruleForm           = res.data;
        state.rightValue         = res.data.station_ids;
        state.form.callingNumber = res.data.callerChange;
        state.form.calleeMode    = res.data.calleeChange ? res.data.calleeChange : [];
        let param                = {
          type: state.ruleForm.target_type
        }
        trunkTarget(param).then(res => {
          if (res.code == 200) {
            state.trunk = res.data;
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const validateFrom     = ref()
    //校验规则
    const rules            = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: t("请输入外呼权重"),
          trigger: "blur"
        }],
        target_type: [{
          required: true,
          message: t("请选择目标类型"),
          trigger: "change"
        }],
        target_id: [{
          required: true,
          message: t("请选择中继或中继组"),
          trigger: "change"
        }],
        call_authority: [{
          required: true,
          message: t("请选择外呼权限"),
          trigger: "change"
        }],
        status: [{
          required: true,
          message: t("请选择状态"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog       = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      state.activeName   = 'first';
      timeSimList(state)
      routeoutEditData(state)
      let params = {
        keyword: 'routeOut',
        id: state.id
      }
      avaliableStation(params).then(res => {
        if (res.code == 200) {
          state.leftValue = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 关闭弹窗
    const closeDialog      = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel         = () => {
      closeDialog();
      initForm();
    };
    // 基本设置新增
    const onSubmit         = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            target_type: state.ruleForm.target_type,
            target_id: state.ruleForm.target_id,
            pattern: state.ruleForm.pattern,
            call_authority: state.ruleForm.call_authority,
            priority: state.ruleForm.priority,
            time_conditions: state.ruleForm.time_conditions,
          };
          updateRouteout(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 改编来电号码新增
    const onSubSec         = () => {
      let params = {
        id: state.id,
        calleeChange: state.form.calleeMode,
      };
      if (state.form.calleeMode.length) {
        for (var i = 0; i < state.form.calleeMode.length; i++) {
          if (!state.form.calleeMode[i].mode) {
            ElNotification({
              title: t("失败"),
              message: t("匹配模式不能为空"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
          if (!/\d+/.test(state.form.calleeMode[i].remove)) {
            ElNotification({
              title: t("失败"),
              message: t("删除前置位数只能设置数字"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
          if (state.form.calleeMode[i].number && !/\+?\d+/.test(state.form.calleeMode[i].number)) {
            ElNotification({
              title: t("失败"),
              message: t("前置号码格式错误"),
              type: 'error',
              duration: 2000,
            });
            return;
          }
        }
      }
      setTransformRouteout(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("号码变换编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog = false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const getObject = (value, direction, movedKeys) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          stations: state.editLayoutData
        }
        routeoutMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("编辑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          stations: state.editLayoutData
        }
        deleteRouteoutMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm  = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };

    //被叫号码添加
    const addCalleeMode = () => {
      state.form.calleeMode.push({
        mode: "",
        remove: "0",
        number: "",
        isEdit: true,
      });
    }
    //被叫号码删除
    const delCalleeMode = (index) => {
      state.form.calleeMode.splice(index, 1);
    }

    //被叫鼠标移入cell
    function BhandleCellEnter(row) {
      let index                           = row.defineIndex;
      state.form.calleeMode[index].isEdit = true
    }

    // 被叫鼠标移出cell
    function BhandleCellLeave(row) {
      let index                           = row.defineIndex;
      state.form.calleeMode[index].isEdit = false
    }

    //获取到每一行的index
    function calledNumberRowIndex({
                                    row,
                                    rowIndex
                                  }) {
      row.defineIndex = rowIndex
    }

    const onType = (row) => {
      let param = {
        type: row,
      }
      trunkTarget(param).then(res => {
        if (res.code == 200) {
          state.trunk = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      onType,
      ...toRefs(state),
      addCalleeMode,
      delCalleeMode,
      BhandleCellEnter,
      BhandleCellLeave,
      calledNumberRowIndex,
      getObject,
      onSubSec,
      validateFrom,
      rules
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.card-header h3 {
  font-size: 15px;
}

.tip {
  float: left;
  position: relative;
  top: 9px !important;
  left: -3px !important;
}
</style>
