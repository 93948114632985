<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增时间条件')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('假期类型')" prop="workday">
              <el-select v-model="ruleForm.workday" filterable clearable :placeholder="$t('请选择假期类型')">
                <el-option :label="$t('所有日期')" value="0"></el-option>
                <el-option :label="$t('工作日')" value="1"></el-option>
                <el-option :label="$t('休息日')" value="2"></el-option>
                <el-option :label="$t('节假日')" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('开始时间')" prop="start_time">
              <el-input v-model="ruleForm.start_time" :placeholder="'00:00'" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('结束时间')" prop="end_time">
              <el-input v-model="ruleForm.end_time" :placeholder="'23:59'" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('星期')" prop="weekday">
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"
                           :disabled="ruleForm.workday!=0" style="margin-right:5px;">
                {{ $t('全选') }}
              </el-checkbox>
              <el-checkbox-group v-model="ruleForm.weekday" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="city in cities" :key="city.value" :label="city.value"
                             :disabled="ruleForm.workday!=0">{{ city.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addTime,
} from '@/http/setUp.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'incomingCallAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}           = useI18n()
    const checkedCities = [
      {
        label: t('星期日'),
        value: '0'
      },
      {
        label: t('星期一'),
        value: '1'
      },
      {
        label: t('星期二'),
        value: '2'
      },
      {
        label: t('星期三'),
        value: '3'
      },
      {
        label: t('星期四'),
        value: '4'
      },
      {
        label: t('星期五'),
        value: '5'
      },
      {
        label: t('星期六'),
        value: '6'
      },
    ]
    const state         = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        start_time: '',
        end_time: '',
        weekday: ['0', '1', '2', '3', '4', '5', '6'],
        workday: '0',
      },
      activeName: 'first',
      tabPosition: 'left',
      value: '',
      balue: '',
      checked1: false,
      form: {
        calledNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
        Time: [{
          value1: '',
          value2: '',
        }],
      },
      checkAll: false,
      cities: checkedCities,
      isIndeterminate: true,
    });
    const validateFrom  = ref()

    //校验规则
    const rules                     = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        workday: [{
          required: true,
          message: t("请选择假期类型"),
          trigger: "blur"
        }],
        start_time: [{
          required: true,
          message: t("请填写开始时间"),
          trigger: "blur"
        }],
        end_time: [{
          required: true,
          message: t("请填写结束时间"),
          trigger: "blur"
        }],
        weekday: [{
          required: true,
          message: t("请选择星期"),
          trigger: "blur"
        }]
      };
      return rule;
    })
    // 打开弹窗
    const openDialog                = () => {
      state.isShowDialog = true;
      initForm();
    };
    // 关闭弹窗
    const closeDialog               = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel                  = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit                  = () => {
      let str = '';
      for (let i = 0; i < state.ruleForm.weekday.length; i++) {
        //区分是不是最后一个
        if (i == state.ruleForm.weekday.length - 1) {
          str += state.ruleForm.weekday[i];
        } else {
          str += state.ruleForm.weekday[i] + ",";
        }
      }
      if (state.ruleForm.workday == 0) {
        validateFrom.value.rules.weekday = [{
          required: true,
          message: t("请选择星期"),
          trigger: "blur"
        }];
      } else {
        validateFrom.value.rules.weekday = null;
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            start_time: state.ruleForm.start_time,
            end_time: state.ruleForm.end_time,
            workday: state.ruleForm.workday,
            weekday: str,
          };
          addTime(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: "成功",
                message: "添加成功",
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: '失败',
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }

          })
        }
      });
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm                  = () => {
      state.ruleForm.name       = '';
      state.ruleForm.start_time = '';
      state.ruleForm.end_time   = '';
      state.ruleForm.weekday    = ['0', '1', '2', '3', '4', '5', '6'];
      state.ruleForm.workday    = '0';
    };
    //时间条件添加
    const cddcalledNumber           = () => {
      state.form.calledNumber.push({
        sourceModule: "1",
        replaceMode: "1"
      });
    }
    //时间条件删除
    const DelcalledNumber           = (index) => {
      state.form.calledNumber.splice(index, 1);
    }
    //时间条件-时间添加
    const addItem                   = () => {
      state.form.Time.push({
        value1: "",
        value2: "",
      });
    }
    //时间条件-时间删除
    const deleteItem                = (index) => {
      state.form.Time.splice(index, 1);
    }
    //时间日期选择器
    const getEstabDate              = (val) => {
      state.ruleForm.start_time = val
    }
    //星期选择
    const handleCheckAllChange      = (val) => {
      let checked            = checkedCities.map(function (item) {
        return item.value;
      });
      state.ruleForm.weekday = val ? checked : []
      state.isIndeterminate  = false
    }
    const handleCheckedCitiesChange = (value) => {
      const checkedCount    = value.length
      state.checkAll        = checkedCount === state.cities.length
      state.isIndeterminate =
          checkedCount > 0 && checkedCount < state.cities.length
    }
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      cddcalledNumber,
      DelcalledNumber,
      deleteItem,
      addItem,
      handleCheckAllChange,
      handleCheckedCitiesChange,
      validateFrom,
      rules,
      getEstabDate,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.timeConditions {
  display: flex;
  justify-content: space-between;
}

.innde {
  width: 100% !important;
}
</style>
