<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增DID匹配分机')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="130px" label-position="top"
               ref="validateFrom"
               :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('DID号码')" prop="did_number">
              <el-input v-model="ruleForm.did_number" :placeholder="$t('DID号码')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('匹配分机')" prop="station_id">
              <el-select v-model="ruleForm.station_id" :placeholder="$t('请选择')">
                <el-option v-for="item in stations" :key="item.id" :label="item.number"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addDidExt,
} from '@/http/routein.api.js';
import {
  ElNotification
} from 'element-plus';
import {avaliableStation} from "@/http/extension.api";

function stationBase(state) {
  avaliableStation().then(res => {
    state.stations = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'routeInAddDidExt',
  components: {},
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        did_number: '',
        station_id: '',
      },
      stations: [],
    });
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        did_number: [{
          required: true,
          message: t("请输入DID号码"),
          trigger: "blur"
        }],
        station_id: [{
          required: true,
          message: t("请选择匹配分机"),
          trigger: "blur"
        }],
      }
      return rule;
    })
    // 打开弹窗
    const openDialog   = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      stationBase(state);
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };

    // 新增
    const onSubmit = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            id: state.id,
            did_number: state.ruleForm.did_number,
            station_id: state.ruleForm.station_id,
          };
          addDidExt(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };

    const initForm = () => {
      state.ruleForm = {
        did_number: '',
        station_id: '',
      };
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  }
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.card-header h3 {
  font-size: 15px;
}
</style>
