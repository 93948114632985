<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增中继组')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('呼叫分配类型')" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio label="0">{{ $t('优先级') }}</el-radio>
                <el-radio label="1">{{ $t('权重') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('中继成员')" prop="type">
              <el-row class="u-el-button-small">
                <el-button type="primary" size="small" @click="addTrunkMem">
                  <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                    <CirclePlus/>
                  </el-icon>
                  <span style="vertical-align: middle">{{ $t('添加') }}</span>
                </el-button>
                <div class="grid-content bg-purple-light">
                  <el-table :data="ruleForm.trunkMem" stripe style="width: 100%;margin-top:15px;" class="table"
                            @cell-mouse-enter="DhandleCellEnter" @cell-mouse-leave="DhandleCellLeave"
                            :row-class-name="DtableRowIndex"
                            id="out-table">
                    <el-table-column prop="sourceModule" :label="$t('中继')" show-overflow-tooltip>
                      <template #default="scope">
                        <el-select v-model="scope.row.trunk" :placeholder="$t('请选择中继')" filterable allow-create
                                   default-first-option>
                          <el-option v-for="item in trunk" :key="item.id" :label="item.name"
                                     :value="item.id"></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="replaceMode" :label="$t('优先级')" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.priority" placeholder=""
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                  maxlength="3" clearable></el-input>
                        <div v-else class="txt">{{ scope.row.priority }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="replaceMode" :label="$t('权重')" show-overflow-tooltip>
                      <template #default="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.weight" placeholder=""
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                  maxlength="3" clearable></el-input>
                        <div v-else class="txt">{{ scope.row.weight }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="path" :label="$t('操作')" width="90">
                      <template #default="scope">
                        <el-button type="text" @click="delTrunkMem(scope.row)" :title="$t('删除')">
                          <el-icon :size="16" color="#f63d3d">
                            <Delete/>
                          </el-icon>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <p class="member">分机成员</p>
            <el-transfer v-model="value" :props="{ key: 'value', label: 'desc',}" :data="data" filterable
              :titles="['可用分机', '成员分机']" :button-texts="['移除', '添加']" filter-placeholder="搜索分机">
            </el-transfer>
          </el-col> -->
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  addTrunkGroup
} from '@/http/group.api.js';

function trunkGroupEdit(state) {
  simpleTrunk().then(res => {
    state.trunk = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'systemAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        type: '0',
        trunkMem: [{
          trunk: '',
          priority: 0,
          weight: 0,
          isEdit: false,
        }],
      },
      trunk: [],
      activeName: 'first',
      tabPosition: 'left',
    });
    const validateFrom = ref()

    //校验规则
    const rules       = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入中继组名称"),
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: t("请选择呼叫分配类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    const addTrunkMem = () => {
      state.ruleForm.trunkMem.push({
        trunk: '',
        priority: 0,
        weight: 0,
        isEdit: true,
      });
    }
    const delTrunkMem = (index) => {
      state.ruleForm.trunkMem.splice(index, 1);
    }

    //DOD鼠标移入cell
    function DhandleCellEnter(row) {
      let index                             = row.defineIndex;
      state.ruleForm.trunkMem[index].isEdit = true
    }

    function DtableRowIndex({
                              row,
                              rowIndex
                            }) {
      row.defineIndex = rowIndex
    }

    // DOD鼠标移出cell
    function DhandleCellLeave(row) {
      let index                             = row.defineIndex;
      state.ruleForm.trunkMem[index].isEdit = false
    }

    // 打开弹窗
    const openDialog  = () => {
      state.isShowDialog = true;
      trunkGroupEdit(state);
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            distribution_type: state.ruleForm.type,
            mem: state.ruleForm.trunkMem,
          };
          addTrunkGroup(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.name     = '';
      state.ruleForm.type     = '0';
      state.ruleForm.trunkMem = [{
        trunk: '',
        priority: 0,
        weight: 0,
      }];
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      addTrunkMem,
      delTrunkMem,
      DhandleCellEnter,
      DhandleCellLeave,
      DtableRowIndex,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
  // data() {
  // 	const generateData = () => {
  // 		const data = []
  // 		for (let i = 1; i <= 15; i++) {
  // 			data.push({
  // 				value: i,
  // 				desc: `Option ${i}`,
  // 			})
  // 		}
  // 		return data
  // 	}
  // 	return {
  // 		data: generateData(),
  // 		value: [],
  // 	}
  // },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.grid-content {
  width: 100%;
}
</style>
