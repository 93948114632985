import request from './index.js'

export function getCollect(data = {}) {
    return request({
        url: "/api/collect/search",
        method: "GET",
        params: data
    })
}

export function delCollect(data = {}) {
    return request({
        url: "/api/collect/delete",
        method: "DELETE",
        data
    })
}

export function batchDelCollect(data = {}) {
    return request({
        url: "/api/collect/batchdel",
        method: "DELETE",
        data
    })
}

export function findCollect(data = {}) {
    return request({
        url: "/api/collect/find",
        method: "GET",
        params: data
    })
}

export function addCollect(data = {}) {
    return request({
        url: "/api/collect/add",
        method: "POST",
        data
    })
}

export function putCollect(data = {}) {
    return request({
        url: "/api/collect/update",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

export function getConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/search",
        method: "GET",
        params: data
    })
}

export function findConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/find",
        method: "GET",
        params: data
    })
}

export function addConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/add",
        method: "POST",
        data
    })
}

export function nowConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/now",
        method: "POST",
        data
    })
}

export function startConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/start",
        method: "POST",
        data
    })
}

export function stopConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/stop",
        method: "POST",
        data
    })
}

export function memberConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/member",
        method: "POST",
        data
    })
}

export function actionConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/action",
        method: "POST",
        data
    })
}

export function putConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/update",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

export function batchdelConfManage(data = {}) {
    return request({
        url: "/api/conference/manage/batchdel",
        method: "DELETE",
        data
    })
}

