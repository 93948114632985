<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑呼入路由')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本信息')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="130px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('状态')" prop="status">
                  <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="">
                  <el-tooltip class="box-item" effect="dark" placement="bottom">
                <span>{{ $t('主叫号码匹配模式') }}
                  <el-icon size="small" color="#409eff">
                    <info-filled/>
                  </el-icon>
                </span>
                    <template #content>
                      {{ $t('特殊字符定义如下') }}:<br/>
                      {{ $t('X代表从0到9的任意数字') }}；<br/>
                      {{ $t('Z代表从1到9的任意数字') }}；<br/>
                      {{ $t('N代表从2到9的任意数字') }}；<br/>
                      [12345-9] <br/>
                      {{ $t('代表括内任数字例如在此例中代表号') }}<br/>
                      {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>
                      {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>
                      {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>
                    </template>
                  </el-tooltip>
                  <el-input v-model="ruleForm.caller_pattern" :placeholder="$t('主叫号码匹配模式')" maxlength="32"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('优先级')" prop="priority">
                  <el-input-number v-model="ruleForm.priority" :placeholder="$t('优先级')" maxlength="3" :min="0"
                                   :max="999"
                                   clearable></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item :label="$t('时间条件')">
                  <el-select v-model="ruleForm.time_conditions" :placeholder="$t('请选择时间条件可多选')" multiple>
                    <el-option v-for="item in times" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer" style="float:right">
              <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
            </span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="DID" name="second">
          <el-form :model="ruleForm" size="small" label-width="130px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('DID匹配模式')" prop="did_type">
                  <el-select v-model="ruleForm.did_type" :placeholder="$t('请选择DID匹配模式')"
                             @change="didTypeChange">
                    <el-option :label="$t('请选择模式')" value="0" disabled></el-option>
                    <el-option :label="$t('DID号码范围匹配到分机范围')" value="1"></el-option>
                    <el-option :label="$t('DID号码规则匹配到分机')" value="2"></el-option>
                    <el-option :label="$t('DID号码匹配到指定分机')" value="3"></el-option>
                    <el-option :label="$t('DID号码规则')" value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==4">
                <el-form-item label="" class="pattern">
                  <el-tooltip class="box-item" effect="dark" placement="bottom">
                    <span>{{ $t('DID号码规则') }}
                      <el-icon size="small" color="#409eff">
                        <info-filled/>
                      </el-icon>
                    </span>
                    <template #content>
                      {{ $t('特殊字符定义如下') }}:<br/>
                      {{ $t('X代表从0到9的任意数字') }}；<br/>
                      {{ $t('Z代表从1到9的任意数字') }}；<br/>
                      {{ $t('N代表从2到9的任意数字') }}；<br/>
                      [12345-9] <br/>
                      {{ $t('代表括内任数字例如在此例中代表号') }}<br/>
                      {{ $t('码') }}：1、2、3、4、5、6、7、8、9；<br/>
                      {{ $t('通配符代表匹配到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码但不包含9011') }}；<br/>
                      {{ $t('通配符代表匹配0到多个数字例如9011') }} <br/>
                      {{ $t('代表匹配任何以9011开头的号码包含9011') }}；<br/>
                    </template>
                  </el-tooltip>
                  <el-input v-model="ruleForm.pattern" :placeholder="$t('DID号码规则')" maxlength="32"
                            clearable></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="ruleForm.did_type==3">
                <el-card shadow="never" class="mb10 u-el-button-small">
                  <template #header>
                    <div style="display: flex;">
                      <el-button type="primary" size="small" @click="onOpenAddDidExt">
                        <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                          <CirclePlus/>
                        </el-icon>
                        <span style="vertical-align: middle">{{ $t('添加') }}</span>
                      </el-button>
                      <el-button type="primary" size="small" @click="onOpenImpDidExt">
                        <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                          <Upload/>
                        </el-icon>
                        <span style="vertical-align: middle">{{ $t('导入') }}</span>
                      </el-button>
                      <el-button type="info" size="small" @click="delDidExtsSub">
                        <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                          <Delete/>
                        </el-icon>
                        <span style="vertical-align: middle">{{ $t('删除') }}</span>
                      </el-button>
                      <el-input size="small" v-model="keyword" :placeholder="$t('DID号码')"
                                style="width:160px;margin-left: auto;" @keydown.enter="didExtList">
                        <template #append>
                          <el-button @click="didExtList">
                            <el-icon :size="14">
                              <Search/>
                            </el-icon>
                          </el-button>
                        </template>
                      </el-input>
                    </div>
                  </template>
                  <el-row :gutter="35">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                      <el-table :data="didExt.data" stripe style="width: 100%" class="table" id="out-table"
                                @selection-change="selectionChange" @sort-change="changeDidSort">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="did_number" :label="$t('DID号码')"
                                         show-overflow-tooltip sortable="custom"></el-table-column>
                        <el-table-column prop="station_dn" :label="$t('匹配分机')"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="create_time" :label="$t('创建时间')"
                                         show-overflow-tooltip sortable="custom" width="180"></el-table-column>
                        <el-table-column prop="path" :label="$t('操作')" width="90" fixed="right">
                          <template #default="scope">
                            <el-button type="text" @click="onOpenEditDidExt(scope.row)" :title="$t('编辑')">
                              <el-icon :size="16">
                                <Edit/>
                              </el-icon>
                            </el-button>
                            <el-button type="text" @click="delDidExtSub(scope.row)" :title="$t('删除')"
                                       style="margin-left: 0px !important;">
                              <el-icon :size="16" color="#f63d3d">
                                <Delete/>
                              </el-icon>
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                                     @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                                     :page-count="didExt.pageCount"
                                     :current-page="didExt.pageNum" background :page-size="didExt.pageSize"
                                     layout="total, prev, pager, next, jumper"
                                     :total="didExt.total">
                      </el-pagination>
                      <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                                     :page-size="didExt.pageSize" small layout=" prev, pager, next"
                                     :page-count="didExt.pageCount"
                                     :current-page="didExt.pageNum"
                                     :total="didExt.total" class="mt15"></el-pagination>
                    </el-col>
                  </el-row>
                </el-card>
              </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==1">
                <el-form-item :label="$t('DID范围')" prop="did_range">
                  <div class="input-number-range">
                    <div class="flex">
                      <div class="from">
                        <el-input
                            ref="input_from"
                            v-model="ruleForm.did_range.start"
                            :placeholder="$t('起始值')"
                            @blur="handleBlurFrom"
                            @focus="handleFocusFrom"
                            @input="handleInputFrom"
                            @change="handleInputChangeFrom"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                      </div>
                      <div class="center">
                        <span>-</span>
                      </div>
                      <div class="to">
                        <el-input
                            ref="input_to"
                            v-model="ruleForm.did_range.end"
                            :placeholder="$t('结束值')"
                            @blur="handleBlurTo"
                            @focus="handleFocusTo"
                            @input="handleInputTo"
                            @change="handleInputChangeTo"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==2">
                <el-form-item :label="$t('DID号码规则')" prop="pattern">
                  <el-input v-model="ruleForm.pattern" :placeholder="$t('DID号码规则')" maxlength="16"
                            clearable></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==1">
                <el-form-item :label="$t('目的类型')" prop="target_type">
                  <el-select v-model="ruleForm.did_type_1" :placeholder="$t('请选择目的类型')">
                    <el-option :label="$t('分机范围')" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==1">
                <el-form-item :label="$t('分机范围')" prop="ext_range">
                  <div class="input-number-range">
                    <div class="flex">
                      <div class="from">
                        <el-input
                            ref="input_from"
                            v-model="ruleForm.ext_range.start"
                            :placeholder="$t('起始值')"
                            @blur="handleExtBlurFrom"
                            @focus="handleExtFocusFrom"
                            @input="handleExtInputFrom"
                            @change="handleExtInputChangeFrom"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                      </div>
                      <div class="center">
                        <span>-</span>
                      </div>
                      <div class="to">
                        <el-input
                            ref="input_to"
                            v-model="ruleForm.ext_range.end"
                            :placeholder="$t('结束值')"
                            @blur="handleExtBlurTo"
                            @focus="handleExtFocusTo"
                            @input="handleExtInputTo"
                            @change="handleExtInputChangeTo"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==4">
                <el-form-item :label="$t('目的类型')" prop="target_type">
                  <el-select v-model="ruleForm.target_type" :placeholder="$t('请选择目的类型')"
                             @change="targetChange">
                    <el-option :label="$t('分机')" value="STATION"></el-option>
                    <el-option :label="$t('分组')" value="GROUP"></el-option>
                    <el-option :label="$t('队列')" value="QUEUE"></el-option>
                    <el-option :label="$t('会议')" value="CONFERENCE"></el-option>
                    <el-option :label="$t('总机')" value="IVR"></el-option>
                    <el-option :label="$t('呼出路由')" value="ROUTEOUT"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.did_type==4">
                <el-form-item :label="$t('目的号码')" prop="target_id">
                  <el-select v-model="ruleForm.target_id" :disabled="ruleForm.type==1" filterable clearable
                             :placeholder="$t('请选择目的号码')">
                    <el-option v-for="item in target_obj" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer" style="float:right">
							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
							<el-button type="primary" @click="onSubSec" size="small">{{ $t('保存') }}</el-button>
						</span>
          </el-form>
          <addDidExtP ref="addDidExtRef" @print="refDidExt"/>
          <editDidExtP ref="editDidExtRef" @print="refDidExt"/>
          <impDidExtP ref="impDidExtRef" @print="refDidExt"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('中继')" name="third">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p style="margin-bottom:10px;">{{ $t('中继') }}</p>
                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'name',}" :data="leftValue" filterable
                             :titles="[$t('可用中继'), $t('已选中继')]" :button-texts="[$t('移除'), $t('添加')]"
                             :filter-placeholder="$t('搜索中继')" @change="getObject">
                </el-transfer>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  findRoutein,
  updateRoutein,
  memberRoutein,
  memberDeleteRoutein,
  targetRoutein, timeSimple, upRouteInDid, getDidExtData, delDidExt, bDelDidExt
} from "@/http/routein.api.js";
import {
  simpleTrunk
} from "@/http/trunk.api.js";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {Delete, Edit, Search} from "@element-plus/icons-vue";
import addDidExtP from "@/views/incomingCall/components/addDidExt.vue";
import editDidExtP from "@/views/incomingCall/components/editDidExt.vue";
import impDidExtP from "@/views/incomingCall/components/impDidExt.vue";

function timeSimList(state) {
  timeSimple().then(res => {
    state.times = res.data;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'incomingCalleditMenu',
  components: {addDidExtP, editDidExtP, impDidExtP, Delete, Edit, Search},
  emits: ["print"],
  setup(props, context) {
    const {t}              = useI18n()
    const state            = reactive({
      isShowDialog: false,
      id: '',
      keyword: '',
      ruleForm: {
        name: '',
        status: '',
        type: '',
        priority: 0,
        did_type: '4',
        did_type_1: '1',
        did_range: {
          start: '',
          end: '',
        },
        ext_range: {
          start: '',
          end: '',
        },
        caller_pattern: '',
        pattern: '',
        target_type: '',
        target_id: '',
        time_conditions: [],
      },
      times: [],
      stations: [],
      target_obj: [],
      target_type: '',
      activeName: 'first',
      tabPosition: 'left',
      value: '',
      checked1: false,
      form: {
        calledNumber: [{
          sourceModule: "1",
          replaceMode: "1",
          isEdit: false
        }],
      },
      rightValue: [],
      leftValue: [],
      editLayoutData: [],
      department: [],
      selectionList: [],
      didExt: {
        data: [],
        pageSize: 5,  //每页数据条数
        pageNum: 1,   //页码
        total: 0,     //总数据条数
        pageCount: 0, //总页数
        sortType: '',
        sortBy: ''
      },
      clientWidth: '',
    });
    const didExtList       = () => {
      let params = {
        id: state.id,
        keyword: state.keyword,
        page: state.didExt.pageNum,
        sort_type: state.didExt.sortType,
        sort_by: state.didExt.sortBy
      }
      getDidExtData(params).then(res => {
        if (res.code == 200) {
          state.didExt.data      = res.data.rows;
          state.didExt.pageCount = res.data.pages;
          state.didExt.pageSize  = res.data.records;
          state.didExt.total     = res.data.total;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const routeinEditData  = (state) => {
      let params = {
        id: state.id
      }
      findRoutein(params).then(res => {
        state.ruleForm    = res.data;
        state.target_type = res.data.target_type
        state.rightValue  = res.data.trunk_ids;
        let param         = {
          type: state.ruleForm.target_type
        }
        targetRoutein(param).then(res => {
          if (res.code == 200) {
            state.target_obj = res.data;
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }).catch(err => {
        console.log(err, 'err');
      })
      let paramr = {
        type: 'routein',
        id: state.id
      }
      simpleTrunk(paramr).then(res => {
        if (res.code == 200) {
          state.leftValue = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const validateFrom     = ref()
    //校验规则
    const rules            = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        priority: [{
          required: true,
          message: t("请输入优先级"),
          trigger: "blur"
        }],
      };
      return rule;
    })
    const addDidExtRef     = ref();
    const editDidExtRef    = ref();
    const impDidExtRef     = ref();
    const onOpenAddDidExt  = () => {
      addDidExtRef.value.openDialog({id: state.id});
    };
    const onOpenEditDidExt = (row) => {
      editDidExtRef.value.openDialog({id: state.id, did_id: row.id});
    };
    const onOpenImpDidExt  = () => {
      impDidExtRef.value.openDialog({id: state.id});
    };
    // 打开弹窗
    const openDialog       = (row) => {
      state.id = row.id;
      timeSimList(state);
      routeinEditData(state);
      didExtList();
      state.isShowDialog = true;
      state.activeName   = 'first';
    };
    //目的类型变化
    const onType           = (row) => {
      let param = {
        type: row,
      }
      targetRoutein(param).then(res => {
        if (res.code == 200) {
          state.department = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    // 关闭弹窗
    const closeDialog      = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel         = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit         = () => {
      validateFrom.value.rules.did_type    = null;
      validateFrom.value.rules.did_range   = null;
      validateFrom.value.rules.ext_range   = null;
      validateFrom.value.rules.target_type = null;
      validateFrom.value.rules.target_id   = null;

      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            status: state.ruleForm.status,
            priority: state.ruleForm.priority,
            caller_pattern: state.ruleForm.caller_pattern,
            time_conditions: state.ruleForm.time_conditions,
          };
          updateRoutein(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 新增
    const onSubSec         = () => {
      validateFrom.value.rules.did_type  = [{
        required: true,
        message: t("请输入DID匹配模式"),
        trigger: "blur"
      }];
      validateFrom.value.rules.did_range = [{
        required: true,
        message: t("请输入DID范围"),
        trigger: "blur"
      }];
      validateFrom.value.rules.ext_range = [{
        required: true,
        message: t("请输入分机范围"),
        trigger: "blur"
      }];

      if (state.ruleForm.did_type == 4) {
        validateFrom.value.rules.target_type = [{
          required: true,
          message: t("请选择目的类型"),
          trigger: "change"
        }];
        validateFrom.value.rules.target_id   = [{
          required: true,
          message: t("请选择目的号码"),
          trigger: "change"
        }];
      } else {
        validateFrom.value.rules.target_type = null;
        validateFrom.value.rules.target_id   = null;
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          let params = {
            id: state.id,
            did_type: state.ruleForm.did_type,
            did_type_1: state.ruleForm.did_type_1,
            did_range: state.ruleForm.did_range,
            ext_range: state.ruleForm.ext_range,
            pattern: state.ruleForm.pattern,
            target_id: state.ruleForm.target_id,
            target_type: state.ruleForm.target_type,
          };
          upRouteInDid(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const getObject       = (value, direction, movedKeys) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          trunks: state.editLayoutData
        }
        memberRoutein(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("添加成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          trunks: state.editLayoutData
        }
        memberDeleteRoutein(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm        = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };
    //时间条件添加
    const cddcalledNumber = () => {
      state.form.calledNumber.push({
        sourceModule: "1",
        replaceMode: "1"
      });
    }
    //时间条件删除
    const DelcalledNumber = (index) => {
      state.form.calledNumber.splice(index, 1);
    }

    const selectionChange = (list) => {
      state.selectionList = list.map(item => {
        return item.id
      });
    }
    const changeDidSort   = (column) => {
      state.didExt.sortBy   = column.prop;
      state.didExt.sortType = column.order;
      didExtList()
    }

    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.didExt.pageNum = val;
      didExtList();
    };

    // 分页改变
    const onHandleSizeChange = (val) => {
      state.didExt.pageSize = val;
      didExtList();
    };

    // did type change
    const didTypeChange = (type) => {
      if (type == 3) {
        didExtList();
      }
    }

    //目的类型变化
    const targetChange = (row) => {
      let param = {
        type: row,
      }
      targetRoutein(param).then(res => {
        if (res.code == 200) {
          state.target_obj = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    function handleBlurFrom(event) {
      context.emit('blurfrom', event)
    }

    function handleFocusFrom(event) {
      context.emit('focusfrom', event)
    }

    function handleBlurTo(event) {
      context.emit('blurto', event)
    }

    function handleFocusTo(event) {
      context.emit('focusto', event)
    }

    function handleInputFrom(value) {
      context.emit('inputfrom', value)
    }

    function handleInputTo(value) {
      context.emit('inputto', value)
    }

    // from输入框change事件
    function handleInputChangeFrom(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [null, state.ruleForm.did_range.end])
        context.emit('changefrom', state.ruleForm.did_range.start)
        return
      }

      // 初始化数字精度
      state.ruleForm.did_range.start = parseInt(value);

      // 如果from > to 将from值替换成to
      if (typeof state.ruleForm.did_range.end === 'number') {
        state.ruleForm.did_start =
            parseFloat(state.ruleForm.did_range.start) <= parseFloat(state.ruleForm.did_range.end)
                ? state.ruleForm.did_range.start
                : state.ruleForm.did_range.end
      }
      context.emit('input', [state.ruleForm.did_range.start, state.ruleForm.did_range.end])
      context.emit('changefrom', state.ruleForm.did_range.start)
    }

    // to输入框change事件
    function handleInputChangeTo(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [state.ruleForm.did_range.start, null])
        context.emit('changefrom', state.ruleForm.did_range.end)
        return
      }

      // 初始化数字精度
      state.ruleForm.did_range.end = parseInt(value);

      // 如果to < tfrom 将to值替换成from
      if (typeof state.ruleForm.did_range.start === 'number') {
        state.ruleForm.did_end =
            parseFloat(state.ruleForm.did_range.end) >= parseFloat(state.ruleForm.did_range.start)
                ? state.ruleForm.did_range.end
                : state.ruleForm.did_range.start
      }
      context.emit('input', [state.ruleForm.did_range.start, state.ruleForm.did_range.end])
      context.emit('changeto', state.ruleForm.did_range.end)
    }

    function handleExtBlurFrom(event) {
      context.emit('blurfrom', event)
    }

    function handleExtFocusFrom(event) {
      context.emit('focusfrom', event)
    }

    function handleExtBlurTo(event) {
      context.emit('blurto', event)
    }

    function handleExtFocusTo(event) {
      context.emit('focusto', event)
    }

    function handleExtInputFrom(value) {
      context.emit('inputfrom', value)
    }

    function handleExtInputTo(value) {
      context.emit('inputto', value)
    }

    // from输入框change事件
    function handleExtInputChangeFrom(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [null, state.ruleForm.ext_range.end])
        context.emit('changefrom', state.ruleForm.ext_range.start)
        return
      }

      // 初始化数字精度
      state.ruleForm.ext_range.start = parseInt(value);

      // 如果from > to 将from值替换成to
      if (typeof state.ruleForm.ext_range.end === 'number') {
        state.ruleForm.ext_start =
            parseFloat(state.ruleForm.ext_range.start) <= parseFloat(state.ruleForm.ext_range.end)
                ? state.ruleForm.ext_range.start
                : state.ruleForm.ext_range.end
      }
      context.emit('input', [state.ruleForm.ext_range.start, state.ruleForm.ext_range.end])
      context.emit('changefrom', state.ruleForm.ext_range.start)
    }

    // to输入框change事件
    function handleExtInputChangeTo(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [state.ruleForm.ext_range.start, null])
        context.emit('changefrom', state.ruleForm.ext_range.end)
        return
      }

      // 初始化数字精度
      state.ruleForm.ext_range.end = parseInt(value);

      // 如果to < tfrom 将to值替换成from
      if (typeof state.ruleForm.ext_range.start === 'number') {
        state.ruleForm.ext_range.end =
            parseFloat(state.ruleForm.ext_range.end) >= parseFloat(state.ruleForm.ext_range.start)
                ? state.ruleForm.ext_range.end
                : state.ruleForm.ext_range.start
      }
      context.emit('input', [state.ruleForm.ext_range.start, state.ruleForm.ext_range.end])
      context.emit('changeto', state.ruleForm.ext_range.end)
    }

    //
    const delDidExtSub = (row) => {
      ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            delDidExt(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                didExtList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }

    //批量删除
    const delDidExtsSub = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的数据"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              bDelDidExt(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  didExtList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }

    const refDidExt = () => {
      didExtList(state);
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      onSubSec,
      ...toRefs(state),
      cddcalledNumber,
      DelcalledNumber,
      getObject,
      onType,
      didExtList,
      addDidExtRef,
      editDidExtRef,
      impDidExtRef,
      validateFrom,
      rules,
      didTypeChange,
      targetChange,
      handleBlurFrom,
      handleBlurTo,
      handleFocusFrom,
      handleFocusTo,
      handleInputFrom,
      handleInputTo,
      handleInputChangeFrom,
      handleInputChangeTo,
      handleExtBlurFrom,
      handleExtBlurTo,
      handleExtFocusFrom,
      handleExtFocusTo,
      handleExtInputFrom,
      handleExtInputTo,
      handleExtInputChangeFrom,
      handleExtInputChangeTo,
      delDidExtSub,
      delDidExtsSub,
      selectionChange,
      changeDidSort,
      onHandleCurrentChange,
      onHandleSizeChange,
      onOpenAddDidExt,
      onOpenEditDidExt,
      onOpenImpDidExt,
      refDidExt,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.item .el-input__suffix {
  top: -7px;
}

.item .el-input__icon {
  height: 24px !important;
  line-height: 24px !important;
}

.u-fa-icon {
  margin-right: 3px;
}
</style>
