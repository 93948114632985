<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增假期')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('假期类型')" prop="code">
              <el-select v-model="ruleForm.code" filterable clearable>
                <el-option :label="$t('休息日')" value="1"></el-option>
                <el-option :label="$t('工作日')" value="2"></el-option>
                <el-option :label="$t('节日')" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('日期')" prop="time">
              <el-date-picker class="innde" v-model="ruleForm.time" type="daterange" @change="getEstabDate"
                              range-separator="-"
                              :start-placeholder="$t('开始时间')" :end-placeholder="$t('结束时间')" :editable="false"
                              format="YYYY-MM-DD" value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('说明')">
              <el-input v-model="ruleForm.remark" :rows="3" type="textarea" maxlength="64"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addDay,
} from '@/http/setUp.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'incomingCallAddMenu',
  emits: ["printr"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        code: '',
        time: '',
        remark: '',
      },
      activeName: 'first',
      tabPosition: 'left',
    });
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        code: [{
          required: true,
          message: t("请选择假期类型"),
          trigger: "blur"
        }],
        time: [{
          required: true,
          message: t("请选择日期"),
          trigger: "blur"
        }],
      };
      return rule;
    })
    //时间日期选择器
    const getEstabDate = (val) => {
      state.ruleForm.time = val
    }
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      initForm();
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit     = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            code: state.ruleForm.code,
            start_time: state.ruleForm.time[0],
            end_time: state.ruleForm.time[1],
            remark: state.ruleForm.remark,
          };
          addDay(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("printr");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }

          })
        }
      });
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.code   = '';
      state.ruleForm.time   = '';
      state.ruleForm.remark = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      validateFrom,
      rules,
      ...toRefs(state),
      getEstabDate,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px !important;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.timeConditions {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 550px) {
  .innde {
    width: 100% !important;
  }
}
</style>
