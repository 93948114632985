import request from './index.js'

//获取呼出路由列表
export function getRouteout(data={}) {
  return request({
    url: "/api/routeout/search",
    method: "GET",
    params: data
  })
}

//呼出路由添加
export function addRouteout(data={}) {
  return request({
    url: "/api/routeout/add",
    method: "POST",
    data
  })
}

//呼出路由导入
export function impRouteout(data = {}) {
  return request({
    url: "/api/routeout/import",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8"
    },
    data
  })
}

//获取单个呼出路由列表
export function findRouteout(data={}) {
  return request({
    url: "/api/routeout/find",
    method: "GET",
    params: data
  })
}

//呼出路由编辑
export function updateRouteout(data={}) {
  return request({
    url: "/api/routeout/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//呼出路由绑定成员
export function routeoutMember(data={}) {
  return request({
    url: "/api/routeout/member",
    method: "POST",
    data
  })
}

//呼出路由解绑成员
export function deleteRouteoutMember(data={}) {
  return request({
    url: "/api/routeout/member",
    method: "DELETE",
    data
  })
}

//呼出路由号码转换设置
export function setTransformRouteout(data={}) {
  return request({
    url: "/api/routeout/set/transform",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//呼出路由删除
export function deleteRouteout(data={}) {
  return request({
    url: "/api/routeout/delete",
    method: "DELETE",
    data
  })
}

//呼出路由批量删除
export function batchdelRouteout(data={}) {
  return request({
    url: "/api/routeout/batchdel",
    method: "DELETE",
    data
  })
}
