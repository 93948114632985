<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('会议控制')" v-model="isShowDialog" width="80%" @close="closeDialog">
      <el-divider></el-divider>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="u-conf-info">
            <div>
              <span>{{ confInfo.number }}（{{ confInfo.name }}）</span>
            </div>
            <div>
              <span>{{ confInfo.remark }}</span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-card shadow="never" class="mb10 u-el-button-small u-conf-card">
            <el-row>
              <el-col :xs="15" :sm="15" :md="15" :lg="15" :xl="15">
                <div class="u-conf-r">
                  <div class="u-conf-rt">
                    <div style="display: flex;justify-content: center;align-items: center;">
                      <i class="el-icon-time"></i>
                      <div style="margin-left: 5px;width:80px;text-align:left;">{{ confHou }}:{{ confMin }}:{{
                          confSec
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!confInfo.status" class="u-conf-rmn">
                    {{ $t('未开始') }}...
                  </div>
                  <div v-if="confInfo.status" class="u-conf-rm">
                    <div v-show="confInfo.status" v-for="(item) in confInfo.member_act" :key="item.id"
                         :class="item.tab==='station' && !item.register?'u-conf-rmi u-conf-rmi-reg':'u-conf-rmi'">
                      <span class="u-conf-rmi-t">
                        <i class="fa fa-street-view u-conf-rmi-icon" v-show="item.promoter"></i>{{
                          item.display_name ? item.display_name : item.dn
                        }}</span>
                      <span class="u-conf-rmi-n">{{ item.dn }}</span>
                      <i class="fa fa-microphone u-conf-rmi-i" @click="actionConf('mute', item.id, item)"
                         v-if="!item.promoter && !item.isMute" :id="'rm-'+item.dn">
                        <span class="u-conf-rmi-mute1"></span>
                        <span class="u-conf-rmi-mute2"></span>
                      </i>
                      <i class="fa fa-microphone u-conf-rmi-i" @click="actionConf('mute', item.id, item)"
                         v-if="item.promoter && !confInfo.isMute" :id="'rmp-'+item.dn">
                        <span class="u-conf-rmi-mute1"></span>
                        <span class="u-conf-rmi-mute2"></span>
                      </i>
                      <i class="fa fa-microphone-slash u-conf-rmi-i" @click="actionConf('unmute', item.id, item)"
                         v-if="!item.promoter && item.isMute"></i>
                      <i class="fa fa-microphone-slash u-conf-rmi-i" @click="actionConf('unmute', item.id, item)"
                         v-if="item.promoter && confInfo.isMute"></i>
                    </div>
                  </div>
                  <div class="u-conf-rb">
                    <div class="u-conf-rbl" v-if="confInfo.status">
                      <div class="u-conf-rbl-i" @click="actionConf('mute',confInfo.promoter_id,'promoter')"
                           v-if="!confInfo.isMute">
                        <i class="fa fa-microphone"></i>
                        <span>{{ $t('静音') }}</span>
                      </div>
                      <div class="u-conf-rbl-i" @click="actionConf('unmute',confInfo.promoter_id,'promoter')"
                           v-if="confInfo.isMute">
                        <i class="fa fa-microphone-slash"></i>
                        <span>{{ $t('静音') }}</span>
                      </div>
                      <div class="u-conf-rbl-i" @click="onOpenAddMem">
                        <i class="fa fa-user-plus"></i>
                        <span>{{ $t('邀请') }}</span>
                      </div>
                    </div>
                    <div class="u-conf-rbr">
                      <div class="u-conf-rbr-t-hangup" v-if="confInfo.status" @click="stopConf">
                        <span>{{ $t('结束会议') }}</span>
                      </div>
                      <div class="u-conf-rbr-t-start" v-if="!confInfo.status" @click="startConf">
                        <span>{{ $t('开始会议') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :xs="9" :sm="9" :md="9" :lg="9" :xl="9">
                <div class="u-conf-l">
                  <div class="u-conf-lt">
                    <i class="fa fa-users"></i>
                    <span class="u-conf-lt-t">{{ $t('会议成员') }}</span>
                  </div>
                  <div class="u-conf-lt-tab">
                    <div :class="selectTab?'u-conf-l-tab-i-l u-conf-l-tab-s':'u-conf-l-tab-i-l'"
                         @click="changeTab">
                      {{ $t('已入会') }}（{{ confInfo.member_act.length }}）
                    </div>
                    <div :class="selectTab?'u-conf-l-tab-i-r':'u-conf-l-tab-i-r u-conf-l-tab-s'"
                         @click="changeTab">
                      {{ $t('未入会') }}（{{ confInfo.member_not.length }}）
                    </div>
                  </div>

                  <div class="u-conf-l-m">
                    <div class="u-conf-l-mi" v-show="!selectTab">
                      <el-checkbox id="u-conf-mem-check" :label="$t('全选')" v-model="selectAll"
                                   @change="onSelectAll" class="checkbox__inner" true-label="1"
                                   false-label="0"></el-checkbox>
                    </div>
                    <div class="u-conf-l-mi" v-for="(item) in tabData" :key="item.id">
                      <div class="u-conf-l-mil">
                        <el-checkbox id="u-conf-mem-check" :label="item.dn" value="1" class="checkbox__inner"
                                     v-model="item.isChecked" @change="onSelectItem(item.id,item.isChecked)"
                                     v-show="!selectTab" :checked="item.promoter"
                                     :disabled="item.promoter"></el-checkbox>
                        <span class="u-conf-l-mi-t">
                          <i class="fa fa-phone u-conf-mem-t-i" v-if="selectTab && item.tab==='station'"></i>
                          <i class="fa fa-address-book u-conf-mem-t-i" v-if="selectTab && item.tab==='collect'"></i>
                          <i class="fa fa-user u-conf-mem-t-i" v-if="selectTab && item.tab==='other'"></i>
                          <span v-show="selectTab">{{ item.dn }}</span>
                          <span v-show="item.display_name && item.display_name!=item.dn">（{{
                              item.display_name
                            }}）</span>
                        </span>
                      </div>
                      <div class="u-conf-l-mim">
                        <el-tag class="u-conf-mem-tag" type="primary" size="mini"
                                v-if="item.register && item.tab==='station'">{{ $t('在线') }}
                        </el-tag>
                        <el-tag class="u-conf-mem-tag" type="info" size="mini"
                                v-if="!item.register && item.tab==='station'">{{ $t('离线') }}
                        </el-tag>
                        <el-tag class="u-conf-mem-tag" type="danger" size="mini" v-if="item.promoter">
                          {{ $t('主持人') }}
                        </el-tag>
                      </div>
                      <div class="u-conf-l-mir">
                        <div class="u-conf-l-mir-i" v-show="selectTab">
                          <i class="fa fa-microphone" @click="actionConf('mute',item.id,item)"
                             v-if="!item.promoter && !item.isMute" :id="'lm-'+item.dn"></i>
                          <i class="fa fa-microphone" @click="actionConf('mute',item.id,item)"
                             v-if="item.promoter && !confInfo.isMute" :id="'lmp-'+item.dn"></i>
                          <i class="fa fa-microphone-slash" @click="actionConf('unmute',item.id,item)"
                             v-if="!item.promoter && item.isMute"></i>
                          <i class="fa fa-microphone-slash" @click="actionConf('unmute',item.id,item)"
                             v-if="item.promoter && confInfo.isMute"></i>
                          <i class="fa fa-volume-up" @click="actionConf('deaf',item.id,item)"
                             v-show="!item.promoter" v-if="!item.isDeaf"></i>
                          <i class="fa fa-volume-off" @click="actionConf('undeaf',item.id,item)"
                             v-show="!item.promoter" v-if="item.isDeaf"></i>
                          <i class="fa fa-times-circle" @click="memConf('remove',item.id,item)"
                             v-show="!item.promoter"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="u-conf-l-b">
                    <div class="u-conf-l-bt" v-show="selectTab" @click="actionConf('mute','','')">
                      <span>{{ $t('全员静音') }}</span>
                    </div>
                    <div class="u-conf-l-bt" v-show="selectTab" @click="actionConf('unmute','','')">
                      <span>{{ $t('解除全员静音') }}</span>
                    </div>
                    <div class="u-conf-l-bt" v-show="!selectTab && confInfo.status"
                         @click="memConf('add','','')">
                      <span>{{ $t('加入会议') }}</span>
                    </div>
                    <div class="u-conf-l-bt" v-show="!selectTab && !confInfo.status">
                      <span>...</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <addMem ref="addMemRef" @refMem="refMem"/>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive, ref,
  toRefs,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  findConfManage,
  memberConfManage,
  putConfManage,
  startConfManage,
  stopConfManage,
  actionConfManage
} from "@/http/conference.api";
import {
  ElNotification
} from 'element-plus';

import addMem from "./memAdd.vue";
import {useStore} from "vuex";

export default {
  name: 'taskData',
  components: {addMem},
  emits: ["print"],
  setup(props, context) {
    const store = useStore()
    const {t}   = useI18n()
    const state = reactive({
      isShowDialog: false,
      id: '',
      wss_type: '',
      wss_url: '',
      confSec: '00',
      confMin: '00',
      confHou: '00',
      confInfo: {
        id: '',
        type: 0,
        status: 0,
        name: '',
        number: '',
        remark: '',
        promoter_id: '',
        isMute: 0,
        member_act_ids: [],
        member_oth_mems: [],
        member_act: [],
        member_not: [],
        start_time: '',
      },
      tabData: [],
      selectTab: 0,
      selectAll: 0,
      selectionList: [],
      clientWidth: '',
    });

    // 当前的WwebSocket对象
    let CURRENT_SOCKET = null;
    let conf_timer     = '';

    const confInfo = () => {
      let params = {
        id: state.id,
      }
      findConfManage(params).then(res => {
        if (res.code == 200) {
          if (res.data.conf_ref) {
            ElNotification({
              title: t('失败'),
              message: t('会议已结束'),
              type: 'error',
              duration: 2000,
            });
            context.emit("refData");
          }
          state.confInfo  = res.data;
          state.selectTab = state.confInfo.status;
          state.tabData   = res.data.status ? res.data.member_act : res.data.member_not;
          if (!res.data.status) {
            state.selectionList.push(res.data.promoter_id);
            if (conf_timer) {
              clearInterval(conf_timer);
              conf_timer    = '';
              state.confHou = '00';
              state.confMin = '00';
              state.confSec = '00';
            }
          } else {
            openWebSocket();
            initConfTime(res.data.start_time);
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    const initConfTime = (time) => {
      if (time && !conf_timer) {
        let n_t    = new Date();
        let s_t    = new Date(time);
        let s_s    = Math.ceil((n_t - s_t) / 1000);
        conf_timer = setInterval(() => {
          s_s++;
          state.confSec = showNum(s_s % 60);
          state.confMin = showNum(parseInt(s_s / 60 % 60));
          state.confHou = showNum(parseInt(s_s / 3600 % 24));
        }, 1000)
      }
    }

    function showNum(num) {
      if (num < 10) {
        return '0' + num;
      }
      return num;
    }

    // 打开弹窗
    const openDialog = (row) => {
      state.wss_type     = store.state.wss_type;
      state.wss_url      = store.state.wss_url;
      state.id           = row.id;
      state.isShowDialog = true;
      confInfo();
    };

    // 关闭弹窗
    const closeDialog = () => {
      if (conf_timer) {
        clearInterval(conf_timer);
        conf_timer = '';
      }
      initForm();
      state.isShowDialog = false;
      if (CURRENT_SOCKET) {
        CURRENT_SOCKET.close();
        CURRENT_SOCKET = null;
      }
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
    };

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm = () => {
      state.confInfo      = {
        id: '',
        type: 0,
        status: 0,
        name: '',
        number: '',
        remark: '',
        promoter_id: '',
        isMute: 0,
        member_act_ids: [],
        member_oth_mems: [],
        member_act: [],
        member_not: [],
        start_time: '',
      };
      state.id            = '';
      state.selectTab     = 0;
      state.tabData       = [];
      state.selectAll     = 0;
      state.selectionList = [];
      state.confHou       = '00';
      state.confMin       = '00';
      state.confSec       = '00';
    };

    const selectionChange = (list) => {
      state.selectionList = list.map(item => {
        return item.id
      });
    }

    const onSubPut = (row) => {
      let params = {
        id: row.id
      }
      putConfManage(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("删除成功"),
            type: "success",
            duration: 2000,
          });
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const startConf = () => {
      let params = {
        id: state.id,
        members: state.selectionList
      }
      startConfManage(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("开启成功"),
            type: "success",
            duration: 2000,
          });
          confInfo();
          state.selectAll     = 0;
          state.selectionList = [];
          context.emit("refData");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const stopConf = () => {
      let params = {
        id: state.id,
      }
      stopConfManage(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("挂断成功"),
            type: "success",
            duration: 2000,
          });
          if (state.confInfo.type == 1) {
            closeDialog();
          } else {
            confInfo();
          }
          context.emit("refData");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const memConf = (handle, mid, o) => {
      if (handle === 'add' && !state.selectionList.length) {
        ElNotification({
          title: t('失败'),
          message: t('请选择成员'),
          type: 'error',
          duration: 2000,
        });
        return;
      }

      let paramType;
      let paramMembers;
      if (typeof o === 'object') {
        paramType    = o.memType === 'in' ? 'self' : 'other';
        paramMembers = o.memType === 'in' ? (handle === 'add' ? state.selectionList : [mid]) : [o.dn]
      } else {
        paramType    = 'self';
        paramMembers = handle === 'add' ? state.selectionList : [mid];
      }
      let params = {
        id: state.id,
        handle: handle,
        type: paramType,
        members: paramMembers,
      }

      memberConfManage(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: handle === 'add' ? t("添加成员成功") : t("移除成员成功"),
            type: "success",
            duration: 2000,
          });
          state.selectAll     = 0;
          state.selectionList = [];
          confInfo();
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    function changeAction(h, o) {
      switch (h) {
        case 'mute':
          if (typeof o === 'string' && o === 'promoter') {
            state.confInfo.isMute = true;
          } else if (typeof o === 'object') {
            o.isMute = true;
            if (o.promoter) {
              state.confInfo.isMute = true;
            }
          }
          break;
        case 'unmute':
          if (typeof o === 'string' && o === 'promoter') {
            state.confInfo.isMute = false;
          } else if (typeof o === 'object') {
            o.isMute = false;
            if (o.promoter) {
              state.confInfo.isMute = false;
            }
          }
          break;
        case 'deaf':
          o.isDeaf = true;
          break;
        case 'undeaf':
          o.isDeaf = false;
          break;
      }
    }

    const actionConf = (handle, mid, o) => {
      if (!state.confInfo.status) {
        ElNotification({
          title: t("失败"),
          message: t("会议未开始"),
          type: "error",
          duration: 2000,
        });
        return;
      }

      let paramMembers;
      let otherMembers = [];
      if (typeof o === 'object') {
        paramMembers = o.memType === 'in' ? [mid] : [];
        otherMembers = o.memType === 'out' ? [o.dn] : [];
      } else if (o === 'promoter') {
        paramMembers = [mid];
      } else {
        paramMembers = state.confInfo.member_act_ids;
        otherMembers = state.confInfo.member_oth_mems;
      }
      let params = {
        id: state.id,
        handle: handle,
        members: paramMembers,
        others: otherMembers,
      }
      actionConfManage(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("操作成功"),
            type: "success",
            duration: 2000,
          });
          if (!mid) {
            state.tabData.forEach(item => {
              if (state.confInfo.member_act_ids.indexOf(item.id) > -1) {
                changeAction(handle, item);
              }
              if (state.confInfo.member_oth_mems.indexOf(item.dn) > -1) {
                changeAction(handle, item);
              }
            })
          } else {
            changeAction(handle, o);
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const refMem = (p) => {
      let newMem = {
        id: p.id,
        display_name: '',
        dn: p.number,
        tab: 'other',
        memType: p.memType,
        promoter: 0,
      };
      state.confInfo.member_act.push(newMem);
      if (!state.selectTab) {
        state.tabData = state.confInfo.member_act;
      }
    }

    const onSelectItem = (id, status) => {
      if (status) {
        state.selectionList.push(id);
      } else {
        let itemKey = state.selectionList.indexOf(id);
        if (itemKey > -1) {
          state.selectionList.splice(itemKey, 1);
        }
      }
    }

    const onSelectAll = () => {
      state.confInfo.member_not.forEach(item => {
        if ((item.isChecked && state.selectAll === '0') || (!item.isChecked && state.selectAll === '1')) {
          if (!item.promoter) {
            onSelectItem(item.id, !item.isChecked);
          }
        }
        if (!item.promoter) {
          item.isChecked = state.selectAll === '1';
        }
      })
    }

    const changeTab = () => {
      state.tabData   = state.selectTab ? state.confInfo.member_not : state.confInfo.member_act;
      state.selectTab = !state.selectTab;
    }

    const addMemRef    = ref();
    const onOpenAddMem = () => {
      addMemRef.value.openDialog({id: state.id});
    };

    function openWebSocket() {
      let REQUEST_WEBSOCKET_URL_PREFIX = state.wss_url;

      if (CURRENT_SOCKET != null) {
        CURRENT_SOCKET.close();
        CURRENT_SOCKET = null;
      }

      CURRENT_SOCKET           = new WebSocket(REQUEST_WEBSOCKET_URL_PREFIX);
      CURRENT_SOCKET.onopen    = event => {
        console.log('连接Socket', event);
      };
      // 从服务器接受到信息时的回调函数
      CURRENT_SOCKET.onmessage = event => {
        let recMsg    = JSON.parse(event.data);
        let recName   = recMsg.confName ?? '';
        let recAction = recMsg.action ?? '';
        let recNumber = recMsg.dn ?? '';

        let lm  = document.getElementById('lm-' + recNumber);
        let rm  = document.getElementById('rm-' + recNumber);
        let lmp = document.getElementById('lmp-' + recNumber);
        let rmp = document.getElementById('rmp-' + recNumber);
        if (recName === state.confInfo.number) {
          console.log('收到服务器响应：', event.data);
          switch (recAction) {
            case 'add-member':
              confInfo();
              break;
            case 'del-member':
              confInfo();
              break;
            case 'start-talking':
              if (lm && !lm.classList.contains('u-conf-lmi-t')) {
                lm.classList.add('u-conf-lmi-t');
              }
              if (lmp && !lmp.classList.contains('u-conf-lmi-t')) {
                lmp.classList.add('u-conf-lmi-t');
              }
              if (rm) {
                if (!rm.getElementsByClassName('u-conf-rmi-mute1')[0].classList.contains('u-conf-rmi-i-b1')) {
                  rm.getElementsByClassName('u-conf-rmi-mute1')[0].classList.add('u-conf-rmi-i-b1', 'ripple');
                }
                if (!rm.getElementsByClassName('u-conf-rmi-mute2')[0].classList.contains('u-conf-rmi-i-b2')) {
                  rm.getElementsByClassName('u-conf-rmi-mute2')[0].classList.add('u-conf-rmi-i-b2', 'ripple');
                }
              }
              if (rmp) {
                if (!rmp.getElementsByClassName('u-conf-rmi-mute1')[0].classList.contains('u-conf-rmi-i-b1')) {
                  rmp.getElementsByClassName('u-conf-rmi-mute1')[0].classList.add('u-conf-rmi-i-b1', 'ripple');
                }
                if (!rmp.getElementsByClassName('u-conf-rmi-mute2')[0].classList.contains('u-conf-rmi-i-b2')) {
                  rmp.getElementsByClassName('u-conf-rmi-mute2')[0].classList.add('u-conf-rmi-i-b2', 'ripple');
                }
              }
              break;
            case 'stop-talking':
              if (lm) {
                lm.classList.remove('u-conf-lmi-t');
              }
              if (lmp) {
                lmp.classList.remove('u-conf-lmi-t');
              }
              if (rm) {
                rm.getElementsByClassName('u-conf-rmi-mute1')[0].classList.remove('u-conf-rmi-i-b1', 'ripple');
                rm.getElementsByClassName('u-conf-rmi-mute2')[0].classList.remove('u-conf-rmi-i-b2', 'ripple');
              }
              if (rmp) {
                rmp.getElementsByClassName('u-conf-rmi-mute1')[0].classList.remove('u-conf-rmi-i-b1', 'ripple');
                rmp.getElementsByClassName('u-conf-rmi-mute2')[0].classList.remove('u-conf-rmi-i-b2', 'ripple');
              }
              break;
            case 'create':
              confInfo();
              break;
            case 'destroyed':
              if (state.confInfo.type == 1) {
                closeDialog();
              } else {
                confInfo();
              }
              break;
          }
        }
      };
      CURRENT_SOCKET.onerror   = err => {
        console.log('WebSocket连接错误:', err);
      };
      CURRENT_SOCKET.onclose   = event => {
        console.log('关闭WebSocket连接!', event);
      };
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      ...toRefs(state),
      addMemRef,
      onOpenAddMem,
      onSubPut,
      refMem,
      selectionChange,
      onSelectItem,
      onSelectAll,
      changeTab,
      startConf,
      stopConf,
      memConf,
      actionConf,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
  margin-bottom: 10px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.item .el-input__suffix {
  top: -7px;
}

.item .el-input__icon {
  height: 24px !important;
  line-height: 24px !important;
}

.u-fa-icon {
  margin-right: 3px;
}

.u-conf-card .el-card__body {
  padding: 0 !important;
}

.u-conf-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.u-conf-l {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.u-conf-r {
  border-right: 1px solid #c7c6c6;
}

.u-conf-lt, .u-conf-rt {
  text-align: center;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #c7c6c6;
}

.u-conf-lt {
  color: #4e9cf1;
}

.u-conf-lt-tab {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #c7c6c6;
  align-items: center;
  height: 30px;
}

.u-conf-l-tab-i-l, .u-conf-l-tab-i-r {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  height: 100%;
}

.u-conf-l-tab-i-l:hover, .u-conf-l-tab-i-r:hover {
  background-color: #f0f0f0;
}

.u-conf-l-tab-i-l {
  border-right: 1px solid #c7c6c6;
}

.u-conf-lt-t {
  margin-left: 5px;
}

.u-conf-l-tab-s {
  background-color: #4e9cf1;
  color: #ffffff;
}

.u-conf-l-tab-s:hover {
  background-color: #71adef;
}

.u-conf-l-mi {
  display: flex;
  padding: 3px 5px;
}

.u-conf-lmi-t {
  color: #4e9cf1;
}

.u-conf-l-mil {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-l-mi-t {
  margin-right: 5px;
}

.u-conf-l-mim {
  display: flex;
  padding: 0 5px;
}

.u-conf-l-mir {
  margin-left: auto;
  cursor: pointer;
}

.u-conf-l-mir-i {
  display: flex;
  align-items: center;
}

.u-conf-l-mir i {
  font-size: 16px;
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.u-conf-mem-tag {
  margin-right: 5px;
}

.u-conf-mem-t-i {
  margin-right: 3px;
}

.u-conf-l-b {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  border-top: 1px solid #f0f0f0;
}

.u-conf-l-bt {
  border: 1px solid #c7c6c6;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.u-conf-l-bt:hover {
  background-color: #4e9cf1;
  color: #ffffff;
}

.u-conf-l-m {
  height: 290px;
  overflow-y: auto;
  padding: 5px;
}

.u-conf-rm {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-template-rows: 110px 110px;
  grid-gap: 10px;
  height: 320px;
  overflow-y: auto;
  background-color: #666565;
}

.u-conf-rmn {
  display: grid;
  justify-content: center;
  padding: 10px;
  height: 320px;
  overflow-y: auto;
  background-color: #666565;
  color: #f0f0f0;
  font-size: 18px;
}

.u-conf-rmi {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #b2b0b0;
  background-color: #ffffff;
}

.u-conf-rmi-reg {
  background-color: #b7b6b6;
}

.u-conf-rmi-t {
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-rmi-icon {
  margin-right: 3px;
  color: #4e9cf1;
}

.u-conf-rmi-n {
  text-align: center;
  padding: 0 5px;
}

.u-conf-rmi-i {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 22px;
  margin-top: 5px;
  color: #4e9cf1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-conf-rmi-i-b1 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #2284f1;
  opacity: 0;
  position: absolute;
  animation: ripple 2s infinite linear;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

.u-conf-rmi-i-b2 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #3b91f1;
  opacity: 0;
  position: absolute;
  animation: ripple 1.5s infinite linear;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

.ripple {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.ripple:before {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.ripple:after {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  25% {
    transform: scale(1.0);
    opacity: 0.1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.3;
  }

  75% {
    transform: scale(1.4);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.u-conf-rb {
  display: flex;
  align-items: center;
  height: 50px;
  border-top: 1px solid #414141;
  background-color: #414141;
}

.u-conf-rbl {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.u-conf-rbl-i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 5px;
  cursor: pointer;
  padding: 3px 8px;
}

.u-conf-rbl-i i {
  font-size: 18px;
}

.u-conf-rbr {
  margin-left: auto;
  padding: 10px;
}

.u-conf-rbr-t-hangup {
  color: #ffffff;
  background-color: #f56c6c;
  border-radius: 5px;
  padding: 2px 8px;
  cursor: pointer;
}

.u-conf-rbr-t-start {
  color: #ffffff;
  background-color: #4e9cf1;
  border-radius: 5px;
  padding: 2px 8px;
  cursor: pointer;
}

</style>
