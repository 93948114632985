<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title">{{ $t('全部会议') }}</h3>
      <div class="system-user-search">
        <div style="padding-bottom:10px;">
          <div>
            <el-button size="small" type="primary" @click="onOpenAddMenu">
              {{ $t('添加') }}
            </el-button>
            <el-button size="small" type="info" @click="deleteDatas">
              {{ $t('删除') }}
            </el-button>
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="display: flex;align-items: center;margin-right:15px;">
            <label for="switch-select-all" style="margin-right:5px;">{{ $t('全选') }}:</label>
            <el-switch id="switch-select-all" v-model="selectAll" active-value="1" inactive-value="0"
                       @change="onSelectAll"/>
          </div>
          <el-input size="small" v-model="input" :placeholder="$t('会议名称或号码')"
                    style="max-width: 220px;margin-right:3px;" @keyup.enter="handleSearch">
            <template #prepend>
              <el-select v-model="confType" placeholder="全部" style="width: 100px" @change="handleSearch">
                <el-option :label="$t('预定义')" value="0"/>
                <el-option :label="$t('即时')" value="1"/>
              </el-select>
            </template>
          </el-input>
          <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
        </div>
      </div>
      <div class="u-conf-content-no" v-if="!total">
        {{ $t('无数据') }}...
      </div>
      <div class="u-conf-content">
        <div :class="item.status?'u-conf-item u-conf-reg':'u-conf-item'" v-for="(item) in tableData" :key="item.id">
          <div class="u-conf-item-top">
            <div class="u-conf-item-title">
              <i class="fa fa-users"></i>
              <span class="u-conf-item-number">{{ item.number }}</span>
            </div>
            <div class="u-conf-item-name">
              <el-checkbox id="u-conf-mem-check" :label="item.name" value="1" class="checkbox__inner"
                           v-model="item.isChecked"
                           @change="onSelectItem(item.id, item.isChecked)"></el-checkbox>
            </div>
            <div class="u-conf-item-name">
              <el-tag size="medium" type="primary" v-show="item.status">{{ $t('进行中') }}</el-tag>
              <el-tag size="medium" type="info" v-show="!item.status">{{ $t('未开始') }}</el-tag>
            </div>
          </div>
          <div class="u-conf-item-dep" @click="onOpenManageMenu(item.id)">
            <span class="u-conf-item-number">{{ $t('会议控制') }}</span>
          </div>
        </div>
      </div>
      <div class="u-conf-page">
        <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                       @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                       :current-page="pageNum" background :page-size="pageSize"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                       :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                       :current-page="pageNum"
                       :total="total" class="mt15"></el-pagination>
      </div>
    </el-card>
    <ManageMenu ref="manageMenuRef" @refData="refData"/>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue';
import ManageMenu from './components/confManage.vue';

import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {useI18n} from "vue-i18n";
import router from "@/router";
import {batchdelConfManage, getConfManage} from "@/http/conference.api";

export default {
  name: 'confModeRe',
  components: {
    ManageMenu,
  },
  setup() {
    let state = reactive({
      sysTitle: "",
      tableData: [],
      input: '',
      confType: '0',
      pageSize: 15,  //每页数据条数
      pageNum: 1,   //页码
      total: 0,     //总数据条数
      pageCount: 0, //总页数
      memCheck: 0,
      selectAll: 0,
      selectionList: [],
      clientWidth: '',
      sortType: '',
      sortBy: ''
    });
    const {t} = useI18n()
    onMounted(() => {
      blackList(state);
      state.clientWidth = window.innerWidth;
    })
    const blackList = (state) => {
      const params = {
        type: state.confType,
        length: state.pageSize,
        page: state.pageNum,
        keyword: state.input,
      }
      getConfManage(params).then(res => {
        if (res.code == 200) {
          state.tableData     = res.data.list;
          state.pageCount     = res.data.pages;
          state.pageSize      = res.data.length;
          state.total         = res.data.rows;
          state.selectionList = [];
          state.sysTitle      = res.tag;
          if (res.tag) {
            router.push('/setUp/systemSetup')
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    const handleSearch = () => {
      let params = {
        type: state.confType,
        length: state.pageSize,
        page: state.pageNum,
        keyword: state.input,
      }
      getConfManage(params).then(res => {
        if (res.code == 200) {
          state.tableData     = res.data.list;
          state.pageCount     = res.data.pages;
          state.pageSize      = res.data.length;
          state.total         = res.data.rows;
          state.selectionList = [];
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const refData = () => {
      blackList(state);
    }

    const manageMenuRef    = ref();
    //  打开批量添加菜单弹窗
    const onOpenManageMenu = (id) => {
      manageMenuRef.value.openDialog({id: id});
    };

    const onOpenAddMenu = () => {
      router.push('/meet/member')
    };

    //批量删除
    const deleteDatas     = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的会议"),
          type: "warning",
          duration: 2000,
        });
      } else {
        ElMessageBox.confirm(t("你确定要删除该会议2吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              batchdelConfManage(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    const selectionChange = (list) => {
      let array           = list.map(item => {
        return item.id
      })
      state.selectionList = array;
    }

    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      blackList(state)
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      blackList(state)
    };

    const onSelectItem = (i, s) => {
      if (s) {
        state.selectionList.push(i);
      } else {
        let itemKey = state.selectionList.indexOf(i);
        if (itemKey > -1) {
          state.selectionList.splice(itemKey, 1);
        }
      }
    }

    const onSelectAll = () => {
      state.tableData.forEach(item => {
        if ((item.isChecked && state.selectAll === '0') || (!item.isChecked && state.selectAll === '1')) {
          onSelectItem(item.id, !item.isChecked);
        }
        item.isChecked = state.selectAll === '1';
      })
    }

    return {
      ...toRefs(state),
      onOpenAddMenu,
      onOpenManageMenu,
      manageMenuRef,
      onHandleSizeChange,
      onHandleCurrentChange,
      deleteDatas,
      selectionChange,
      onSelectItem,
      onSelectAll,
      handleSearch,
      refData,
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

:deep(.el-input__suffix) {
  display: flex;
  align-items: center;
}

:deep(.el-input-group__prepend) {
  padding: 0px 10px;
}

:deep(.el-input__inner) {
  padding: 0px 15px;
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.u-conf-content-no {
  text-align: center;
  width: 100%;
  padding: 15px;
}

.u-conf-content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-gap: 10px;
}

.u-conf-item {
  width: 160px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.u-conf-item-top {
  border: 1px solid #c8c8c8;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.u-conf-reg {
  background-color: #ecf5ff;
  color: #409eff;
}

.u-conf-reg .u-conf-item-dep {
  background-color: #77b3f4;
  color: #ffffff;
}

.u-conf-reg .u-conf-item-dep:hover {
  background-color: #4e9cf1;
}

.u-conf-item-info {
  margin-left: auto;
  color: #409eff;
  cursor: pointer;
}

.u-conf-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
}

.u-conf-item-number {
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-item-name {
  text-align: center;
  padding: 3px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-conf-item-dep {
  border: 1px solid #c8c8c8;
  border-top: none;
  background: #f0f0f0;
  padding: 3px 5px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.u-conf-item-dep:hover {
  background-color: #d3d2d2;
}

.u-conf-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  align-items: center;
}

.u-conf-btn-title {
  margin-right: 10px;
  color: #40b8fb;
  font-weight: 600;
  font-size: 16px;
}
</style>
