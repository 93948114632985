import request from './index.js'

//获取时间简化列表
export function timeSimple(data = {}) {
  return request({
    url: "/api/time/simple",
    method: "GET",
    params: data
  })
}

//获取呼入路由列表
export function getRoutein(data={}) {
  return request({
    url: "/api/routein/search",
    method: "GET",
    params: data
  })
}

//呼入路由添加
export function addRoutein(data={}) {
  return request({
    url: "/api/routein/add",
    method: "POST",
    data
  })
}

//获取单个呼入路由列表
export function findRoutein(data={}) {
  return request({
    url: "/api/routein/find",
    method: "GET",
    params: data
  })
}

//获取呼入路由目的号码
export function targetRoutein(data={}) {
  return request({
    url: "/api/routein/target",
    method: "GET",
    params: data
  })
}

//呼入路由编辑
export function updateRoutein(data={}) {
  return request({
    url: "/api/routein/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//呼入路由编辑did
export function upRouteInDid(data={}) {
  return request({
    url: "/api/routein/did",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//获取路由did匹配分机列表
export function getDidExtData(data = {}) {
  return request({
    url: "/api/routein/did/search",
    method: "GET",
    params: data
  })
}

//获取路由did匹配分机信息
export function getDidExtInfo(data = {}) {
  return request({
    url: "/api/routein/did/find",
    method: "GET",
    params: data
  })
}

//新增did匹配分机信息
export function addDidExt(data = {}) {
  return request({
    url: "/api/routein/did/add",
    method: "POST",
    params: data
  })
}

//导入did匹配分机信息
export function impDidExt(data = {}) {
  return request({
    url: "/api/routein/did/import",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8"
    },
    data
  })
}


//编辑did匹配分机信息
export function updateDidExtInfo(data = {}) {
  return request({
    url: "/api/routein/did/update",
    method: "PUT",
    params: data
  })
}

//删除did匹配分机信息
export function delDidExt(data = {}) {
  return request({
    url: "/api/routein/did/delete",
    method: "DELETE",
    params: data
  })
}

//批量删除did匹配分机信息
export function bDelDidExt(data = {}) {
  return request({
    url: "/api/routein/did/batchdel",
    method: "DELETE",
    params: data
  })
}

//呼入路由绑定成员
export function memberRoutein(data={}) {
  return request({
    url: "/api/routein/member",
    method: "POST",
    data
  })
}

//呼入路由解绑成员
export function memberDeleteRoutein(data={}) {
  return request({
    url: "/api/routein/member",
    method: "DELETE",
    data
  })
}

//呼入路由删除
export function DeleteRoutein(data={}) {
  return request({
    url: "/api/routein/delete",
    method: "DELETE",
    data
  })
}

//呼入路由批量删除
export function batchdelRoutein(data={}) {
  return request({
    url: "/api/routein/batchdel",
    method: "DELETE",
    data
  })
}
